.hero {
    @include font-style;
    padding-top: 120px;
    padding-bottom: 30px;

    img {
        max-width: 100%;
    }

    p {
        @include padding-top-third;
        @include padding-bottom-third;
        font-weight: 500;
        max-width: 570px;
    }

    .h1-bounded {
        max-width: 700px;
    }

    .content-box {
        display: grid;
        grid-template-columns: 8fr 1fr;
        grid-template-rows: 1fr 4fr;
        grid-column-gap: 0;
        grid-row-gap: 0;

        h1 {
            line-height: 1.2;
            grid-area: 2 / 1 / 3 / 2;
        }

        img {
            grid-area: 1 / 1 / 3 / 3;
        }
    }
}

.hero-employer {
    padding-top: 80px;

    h1 {
        font-size: 2.3rem;
    }
}

.hero-insurance {
    padding-top: 140px;
}

.hero-employer, .hero-insurance {
    .img-mb-bottom {
        padding-top: 160px;
    }
}


@media only screen and (min-width: 400px) {
    .hero {
        .content {
            padding: 0 2rem;
        }
    }
}


@media only screen and (min-width: 500px) {
    .hero {
        .content-box-patient {
            h1 {
                margin-top: 50px;
            }
        }
    }

    .hero-insurance {
        h1 {
            font-size: 2.3rem;
        }
    }
}


@include media-breakpoint-up(sm) {
    .hero {
        .content {
           padding: 0;
        }
    }
}

@include media-breakpoint-up(md) {
    .hero {
        height: 750px;
        padding-top: 150px;

        p {
            max-width: 400px;
        }

        .content {
            z-index: 2;
            position: relative;
            padding-top: 10px;
        }

        h1 {
            margin-top: 0px !important;
            margin-left: 3%;
            font-size: 3.5rem !important;
        }

        .content-box {
            display: block;
        }

        .content-box-patient {
            h1 {
                font-size: 2.7rem !important;
            }
        }

        .f-1,
        p {
            margin-left: 3%;
        }

        img {
            position: absolute;
            top: 100px;
            right: 0;
            z-index: 1;
        }

        img.img-pd {
            height: 500px;
        }

        img.img-sub {
            height: 400px;
        }
    }

    .hero-insurance {
        h1 {
            font-size: 3.3rem !important;
        }
    }
}

@include media-breakpoint-up(lg) {
    .hero {
        .container {
            margin-top: 0;
        }

        .content {
            padding-top: 0px;
        }

        .content-box-patient {
            max-width: 75%;
            h1 {
                font-size: 3.5rem !important;
            }
        }

        p {
            max-width: 500px;
        }

        img {
            right: 8%;
        }
    }

    .hero-insurance {
        h1 {
            font-size: 3.5rem !important;
        }
    }
}

@include media-breakpoint-up(xl) {
    .hero {
        p {
            max-width: 570px;
        }

        img {
            right: 15%;
        }

        img.img-pd {
            height: 678px;
        }

        img.img-sub {
            height: 600px;
        }
    }
}