// NAV
// ***
.navbar {
  background-color: $brand-app;
  top: 0;
  padding: 0 0.1rem;
  transition: padding-top 0.3s, margin-top 0.3s;

  .container {
    overflow: visible;
    transition: margin-top 0.3s;
    margin-top: 33px;
    padding-bottom: 30px;
  }

  .navbar-brand:focus {
    background-color: transparent !important;
  }
}

.navbar-logo-outer {
  width: 100px
}

.navbar-logo {
  transition: width 0.3s, margin 0.3s;
  max-height: 70px;
  width: 100%;
  height: auto;
}

.nav-item {

  .dropdown-menu {
    background-color: #def8f4;
    border: none;
  }

  .dropdown-item {
    border: none !important;
  }

  .dropdown-item:active, .dropdown-item:focus, .dropdown-item:hover {
    background-color: transparent !important;
  }

}

.nav-link {
  font-size: 12px;
  font-weight: 500;
  color: $brand-heading-dark;

  img {
    margin-top: -6px;
  }

  span {
    padding-top: 8px;
  }
}

.nav-link {
  color: rgba(0, 0, 0, 0.5) !important;
}

.nav-link.activeItem {
  color: #106868 !important;
}

// this is the mobile hide mobile animation end result
.navbar.navbar-animate {
  padding-top: 0px;
  margin-top: -160px;

  .container {
    margin-top: 0;
  }
}


// this is a bootstrap class override
.navbar-nav {
  display: block;
}

.navbar-nav .dropdown-toggle::after {
  display: none;
}

@media only screen and (min-width: 400px) {
  .navbar {
    padding: 0 2rem;
  }
}


@include media-breakpoint-up(sm) {

  .navbar {
    .container {
      padding-bottom: 0px;
    }
  }

  .navbar-logo-outer {
    width: 130px
  }

  .navbar-nav {
    display: flex;
    margin-bottom: 0px;
  }

  // this is the desktop shrink animation end result
  .navbar.navbar-animate {
    padding-top: 0px;
    margin-top: 0;

    .navbar-logo {
      width: 90%;
      margin: 5px 0;
    }

    .container {
      margin-top: 0;
    }
  }
}

@include media-breakpoint-up(md) {

  .navbar-logo {
    width: 100%;
  }
  .nav-item {
    a {
      font-size: 17px;
    }
  }
}