.horizontal-scroll {
    overflow: auto;
    display: flex;
    position: static;
    opacity: 0;
}

.illustration {
    scroll-snap-align: start;
}

.horizontal-scroll.animate-show {
    opacity: 1;
    -webkit-animation: ease-in;
    -webkit-animation-name: run;
    -webkit-animation-duration: 1.5s;
}

@-webkit-keyframes run {
    0% {
        position: absolute;
        left: 95vw;
    }

    99% {
        left: -90vw;
    }

    100% {
        position: static;
        scroll-snap-type: x mandatory;
    }
}

@include media-breakpoint-up(lg) {
    .circles .horizontal-scroll {
        display: none;
    }
}