// white button
// default styles for our buttons
// here you can add styles that should apply to all buttons
// uses bootstrap cusotm button mixin and extends it
.bob-style {
    @include button-variant($hero-btn, rgb(145, 145, 145), $hero-btn, rgb(146, 146, 146));
    margin: 3px; //this is needed tp teh background shadow does not get cut off on active
    border-radius: 40px;
    border: none;
    font-size: 17px;
    font-weight: bold;
    line-height: 1.5;
    padding: 15px 90px;
}

.bob-style.small {
    padding: 10px 40px;
}

.cta .bob-style,
.boxes .bob-style {
    @include button-variant(#fff, rgb(145, 145, 145), #fff, rgb(146, 146, 146));
    width: 100%;
    padding: 12px 12px;
    margin-left: -10px;
}

@include media-breakpoint-up(md) {
    .cta .bob-style{
        max-width: 300px;
    }
}

.bob-style-bordered {
    border-color: rgb(146, 146, 146);
    -webkit-box-shadow: 0 0 0 0.2rem rgba(162, 162, 162, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(162, 162, 162, 0.5);
}
