.program-finder {
    @include padding-top-half;
    position: relative;
    padding-bottom: 60px;

    background-color: $brand-custom;

    @include media-breakpoint-up(lg) {
        h2 {
            font-size: 55px !important;
        }
    }

    .cont-select-program::after {
        content: "▼";
        position: absolute;
        top: 10px;
        right: 3rem;
        font-size: 1.2rem;
        color: black;
    }

    #selectDisease, #selectInsurance {
        -webkit-appearance: none;
        appearance: none;
        height: 50px;
        font-style: normal;
        border: none;
        border-radius: 2rem;
        padding: 0.375rem 3rem;
    }

    #cont-btn-program {
        z-index: 999;
    }
}