@charset "UTF-8";
.hyphens {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-phone: 480px;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1500px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Jost";
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.6;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container-phone, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container-phone, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container-phone, .container {
    max-width: 960px;
  }
}
@media (min-width: 1500px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container-phone, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col-phone,
.col-phone-auto, .col-phone-12, .col-phone-11, .col-phone-10, .col-phone-9, .col-phone-8, .col-phone-7, .col-phone-6, .col-phone-5, .col-phone-4, .col-phone-3, .col-phone-2, .col-phone-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 480px) {
  .col-phone {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-phone-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-phone-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-phone-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-phone-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-phone-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-phone-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-phone-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-phone-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-phone-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-phone-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-phone-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-phone-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-phone-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-phone-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-phone-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-phone-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-phone-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-phone-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-phone-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-phone-first {
    order: -1;
  }
  .order-phone-last {
    order: 13;
  }
  .order-phone-0 {
    order: 0;
  }
  .order-phone-1 {
    order: 1;
  }
  .order-phone-2 {
    order: 2;
  }
  .order-phone-3 {
    order: 3;
  }
  .order-phone-4 {
    order: 4;
  }
  .order-phone-5 {
    order: 5;
  }
  .order-phone-6 {
    order: 6;
  }
  .order-phone-7 {
    order: 7;
  }
  .order-phone-8 {
    order: 8;
  }
  .order-phone-9 {
    order: 9;
  }
  .order-phone-10 {
    order: 10;
  }
  .order-phone-11 {
    order: 11;
  }
  .order-phone-12 {
    order: 12;
  }
  .offset-phone-0 {
    margin-left: 0;
  }
  .offset-phone-1 {
    margin-left: 8.33333333%;
  }
  .offset-phone-2 {
    margin-left: 16.66666667%;
  }
  .offset-phone-3 {
    margin-left: 25%;
  }
  .offset-phone-4 {
    margin-left: 33.33333333%;
  }
  .offset-phone-5 {
    margin-left: 41.66666667%;
  }
  .offset-phone-6 {
    margin-left: 50%;
  }
  .offset-phone-7 {
    margin-left: 58.33333333%;
  }
  .offset-phone-8 {
    margin-left: 66.66666667%;
  }
  .offset-phone-9 {
    margin-left: 75%;
  }
  .offset-phone-10 {
    margin-left: 83.33333333%;
  }
  .offset-phone-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1500px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 480px) {
  .dropdown-menu-phone-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-phone-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1500px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.9675rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.8rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.4rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.4rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.4rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.4rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 3px 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 479.98px) {
  .navbar-expand-phone > .container,
  .navbar-expand-phone > .container-fluid,
  .navbar-expand-phone > .container-sm,
  .navbar-expand-phone > .container-md,
  .navbar-expand-phone > .container-lg,
  .navbar-expand-phone > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 480px) {
  .navbar-expand-phone {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-phone .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-phone .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-phone .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-phone > .container,
  .navbar-expand-phone > .container-fluid,
  .navbar-expand-phone > .container-sm,
  .navbar-expand-phone > .container-md,
  .navbar-expand-phone > .container-lg,
  .navbar-expand-phone > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-phone .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-phone .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-phone .navbar-toggler {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1499.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1500px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: white;
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.8);
  border-color: white;
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.8);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 480px) {
  .d-phone-none {
    display: none !important;
  }
  .d-phone-inline {
    display: inline !important;
  }
  .d-phone-inline-block {
    display: inline-block !important;
  }
  .d-phone-block {
    display: block !important;
  }
  .d-phone-table {
    display: table !important;
  }
  .d-phone-table-row {
    display: table-row !important;
  }
  .d-phone-table-cell {
    display: table-cell !important;
  }
  .d-phone-flex {
    display: flex !important;
  }
  .d-phone-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1500px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 480px) {
  .flex-phone-row {
    flex-direction: row !important;
  }
  .flex-phone-column {
    flex-direction: column !important;
  }
  .flex-phone-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-phone-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-phone-wrap {
    flex-wrap: wrap !important;
  }
  .flex-phone-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-phone-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-phone-fill {
    flex: 1 1 auto !important;
  }
  .flex-phone-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-phone-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-phone-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-phone-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-phone-start {
    justify-content: flex-start !important;
  }
  .justify-content-phone-end {
    justify-content: flex-end !important;
  }
  .justify-content-phone-center {
    justify-content: center !important;
  }
  .justify-content-phone-between {
    justify-content: space-between !important;
  }
  .justify-content-phone-around {
    justify-content: space-around !important;
  }
  .align-items-phone-start {
    align-items: flex-start !important;
  }
  .align-items-phone-end {
    align-items: flex-end !important;
  }
  .align-items-phone-center {
    align-items: center !important;
  }
  .align-items-phone-baseline {
    align-items: baseline !important;
  }
  .align-items-phone-stretch {
    align-items: stretch !important;
  }
  .align-content-phone-start {
    align-content: flex-start !important;
  }
  .align-content-phone-end {
    align-content: flex-end !important;
  }
  .align-content-phone-center {
    align-content: center !important;
  }
  .align-content-phone-between {
    align-content: space-between !important;
  }
  .align-content-phone-around {
    align-content: space-around !important;
  }
  .align-content-phone-stretch {
    align-content: stretch !important;
  }
  .align-self-phone-auto {
    align-self: auto !important;
  }
  .align-self-phone-start {
    align-self: flex-start !important;
  }
  .align-self-phone-end {
    align-self: flex-end !important;
  }
  .align-self-phone-center {
    align-self: center !important;
  }
  .align-self-phone-baseline {
    align-self: baseline !important;
  }
  .align-self-phone-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1500px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 480px) {
  .float-phone-left {
    float: left !important;
  }
  .float-phone-right {
    float: right !important;
  }
  .float-phone-none {
    float: none !important;
  }
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1500px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 480px) {
  .m-phone-0 {
    margin: 0 !important;
  }
  .mt-phone-0,
  .my-phone-0 {
    margin-top: 0 !important;
  }
  .mr-phone-0,
  .mx-phone-0 {
    margin-right: 0 !important;
  }
  .mb-phone-0,
  .my-phone-0 {
    margin-bottom: 0 !important;
  }
  .ml-phone-0,
  .mx-phone-0 {
    margin-left: 0 !important;
  }
  .m-phone-1 {
    margin: 0.25rem !important;
  }
  .mt-phone-1,
  .my-phone-1 {
    margin-top: 0.25rem !important;
  }
  .mr-phone-1,
  .mx-phone-1 {
    margin-right: 0.25rem !important;
  }
  .mb-phone-1,
  .my-phone-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-phone-1,
  .mx-phone-1 {
    margin-left: 0.25rem !important;
  }
  .m-phone-2 {
    margin: 0.5rem !important;
  }
  .mt-phone-2,
  .my-phone-2 {
    margin-top: 0.5rem !important;
  }
  .mr-phone-2,
  .mx-phone-2 {
    margin-right: 0.5rem !important;
  }
  .mb-phone-2,
  .my-phone-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-phone-2,
  .mx-phone-2 {
    margin-left: 0.5rem !important;
  }
  .m-phone-3 {
    margin: 1rem !important;
  }
  .mt-phone-3,
  .my-phone-3 {
    margin-top: 1rem !important;
  }
  .mr-phone-3,
  .mx-phone-3 {
    margin-right: 1rem !important;
  }
  .mb-phone-3,
  .my-phone-3 {
    margin-bottom: 1rem !important;
  }
  .ml-phone-3,
  .mx-phone-3 {
    margin-left: 1rem !important;
  }
  .m-phone-4 {
    margin: 1.5rem !important;
  }
  .mt-phone-4,
  .my-phone-4 {
    margin-top: 1.5rem !important;
  }
  .mr-phone-4,
  .mx-phone-4 {
    margin-right: 1.5rem !important;
  }
  .mb-phone-4,
  .my-phone-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-phone-4,
  .mx-phone-4 {
    margin-left: 1.5rem !important;
  }
  .m-phone-5 {
    margin: 3rem !important;
  }
  .mt-phone-5,
  .my-phone-5 {
    margin-top: 3rem !important;
  }
  .mr-phone-5,
  .mx-phone-5 {
    margin-right: 3rem !important;
  }
  .mb-phone-5,
  .my-phone-5 {
    margin-bottom: 3rem !important;
  }
  .ml-phone-5,
  .mx-phone-5 {
    margin-left: 3rem !important;
  }
  .p-phone-0 {
    padding: 0 !important;
  }
  .pt-phone-0,
  .py-phone-0 {
    padding-top: 0 !important;
  }
  .pr-phone-0,
  .px-phone-0 {
    padding-right: 0 !important;
  }
  .pb-phone-0,
  .py-phone-0 {
    padding-bottom: 0 !important;
  }
  .pl-phone-0,
  .px-phone-0 {
    padding-left: 0 !important;
  }
  .p-phone-1 {
    padding: 0.25rem !important;
  }
  .pt-phone-1,
  .py-phone-1 {
    padding-top: 0.25rem !important;
  }
  .pr-phone-1,
  .px-phone-1 {
    padding-right: 0.25rem !important;
  }
  .pb-phone-1,
  .py-phone-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-phone-1,
  .px-phone-1 {
    padding-left: 0.25rem !important;
  }
  .p-phone-2 {
    padding: 0.5rem !important;
  }
  .pt-phone-2,
  .py-phone-2 {
    padding-top: 0.5rem !important;
  }
  .pr-phone-2,
  .px-phone-2 {
    padding-right: 0.5rem !important;
  }
  .pb-phone-2,
  .py-phone-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-phone-2,
  .px-phone-2 {
    padding-left: 0.5rem !important;
  }
  .p-phone-3 {
    padding: 1rem !important;
  }
  .pt-phone-3,
  .py-phone-3 {
    padding-top: 1rem !important;
  }
  .pr-phone-3,
  .px-phone-3 {
    padding-right: 1rem !important;
  }
  .pb-phone-3,
  .py-phone-3 {
    padding-bottom: 1rem !important;
  }
  .pl-phone-3,
  .px-phone-3 {
    padding-left: 1rem !important;
  }
  .p-phone-4 {
    padding: 1.5rem !important;
  }
  .pt-phone-4,
  .py-phone-4 {
    padding-top: 1.5rem !important;
  }
  .pr-phone-4,
  .px-phone-4 {
    padding-right: 1.5rem !important;
  }
  .pb-phone-4,
  .py-phone-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-phone-4,
  .px-phone-4 {
    padding-left: 1.5rem !important;
  }
  .p-phone-5 {
    padding: 3rem !important;
  }
  .pt-phone-5,
  .py-phone-5 {
    padding-top: 3rem !important;
  }
  .pr-phone-5,
  .px-phone-5 {
    padding-right: 3rem !important;
  }
  .pb-phone-5,
  .py-phone-5 {
    padding-bottom: 3rem !important;
  }
  .pl-phone-5,
  .px-phone-5 {
    padding-left: 3rem !important;
  }
  .m-phone-n1 {
    margin: -0.25rem !important;
  }
  .mt-phone-n1,
  .my-phone-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-phone-n1,
  .mx-phone-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-phone-n1,
  .my-phone-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-phone-n1,
  .mx-phone-n1 {
    margin-left: -0.25rem !important;
  }
  .m-phone-n2 {
    margin: -0.5rem !important;
  }
  .mt-phone-n2,
  .my-phone-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-phone-n2,
  .mx-phone-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-phone-n2,
  .my-phone-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-phone-n2,
  .mx-phone-n2 {
    margin-left: -0.5rem !important;
  }
  .m-phone-n3 {
    margin: -1rem !important;
  }
  .mt-phone-n3,
  .my-phone-n3 {
    margin-top: -1rem !important;
  }
  .mr-phone-n3,
  .mx-phone-n3 {
    margin-right: -1rem !important;
  }
  .mb-phone-n3,
  .my-phone-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-phone-n3,
  .mx-phone-n3 {
    margin-left: -1rem !important;
  }
  .m-phone-n4 {
    margin: -1.5rem !important;
  }
  .mt-phone-n4,
  .my-phone-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-phone-n4,
  .mx-phone-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-phone-n4,
  .my-phone-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-phone-n4,
  .mx-phone-n4 {
    margin-left: -1.5rem !important;
  }
  .m-phone-n5 {
    margin: -3rem !important;
  }
  .mt-phone-n5,
  .my-phone-n5 {
    margin-top: -3rem !important;
  }
  .mr-phone-n5,
  .mx-phone-n5 {
    margin-right: -3rem !important;
  }
  .mb-phone-n5,
  .my-phone-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-phone-n5,
  .mx-phone-n5 {
    margin-left: -3rem !important;
  }
  .m-phone-auto {
    margin: auto !important;
  }
  .mt-phone-auto,
  .my-phone-auto {
    margin-top: auto !important;
  }
  .mr-phone-auto,
  .mx-phone-auto {
    margin-right: auto !important;
  }
  .mb-phone-auto,
  .my-phone-auto {
    margin-bottom: auto !important;
  }
  .ml-phone-auto,
  .mx-phone-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1500px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 480px) {
  .text-phone-left {
    text-align: left !important;
  }
  .text-phone-right {
    text-align: right !important;
  }
  .text-phone-center {
    text-align: center !important;
  }
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1500px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

body {
  font-weight: 400;
  font-style: normal;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div#top {
  position: absolute;
  top: 0px;
  visibility: hidden;
  z-index: -1;
}

div#bottom {
  position: relative;
  top: -50px;
}

.container {
  padding: 0;
  overflow: hidden;
}

@media (max-width: 767.98px) {
  .container {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 575.98px) {
  .container {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.bob-frame-content {
  display: none;
}

.scroll-page.position-top > div:first-child {
  padding-top: 160px;
}

div, a, a:link, a:hover, a:visited,
input, button, label {
  -webkit-tap-highlight-color: rgba(200, 200, 200, 0); /* alpha=0 disables default highlighting */
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  text-rendering: auto;
  font-family: "Jost";
  margin-bottom: 12px;
}

h1, .h1 {
  font-weight: 300;
}

h2, .h2 {
  font-weight: 300;
}

h3, .h3 {
  font-weight: 400;
}

h4, .h4 {
  font-weight: 600;
}

h1 small, .h1 small {
  font-size: 1.35rem;
  font-weight: 300;
}

h2 small, .h2 small,
h3 small, .h3 small,
h4 small, .h4 small {
  font-size: 1.125rem;
  font-weight: 300;
}

h1.legend {
  padding-bottom: 6px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 12px;
}

.font-thin {
  font-weight: 300;
}

.formal-content.terms a {
  hyphens: auto;
}

nobr {
  white-space: nowrap;
  hyphens: none;
}

blockquote {
  margin-bottom: 12px;
  border-left: 0 none;
}
blockquote p {
  font-size: 1.35rem;
  line-height: 1.5;
  font-weight: 300;
  font-style: italic;
  position: relative;
}
blockquote p:last-child {
  margin-bottom: 0;
}
blockquote p:before {
  position: absolute;
  top: -80px;
  left: -40px;
  z-index: -1;
  content: "„";
  font-size: 100px;
  font-weight: 600;
  font-style: italic;
  color: var(--light);
}

input[type=submit] {
  font-style: normal;
  font-weight: 300;
}

input,
textarea,
button {
  -webkit-font-smoothing: inherit;
}

.btn-custom {
  color: #fff;
  background-color: #0ca722;
  border-color: #0ca722;
}
.btn-custom:hover {
  color: #fff;
  background-color: #09831b;
  border-color: #097718;
}
.btn-custom:focus, .btn-custom.focus {
  color: #fff;
  background-color: #09831b;
  border-color: #097718;
  box-shadow: 0 0 0 0.2rem rgba(48, 180, 67, 0.5);
}
.btn-custom.disabled, .btn-custom:disabled {
  color: #fff;
  background-color: #0ca722;
  border-color: #0ca722;
}
.btn-custom:not(:disabled):not(.disabled):active, .btn-custom:not(:disabled):not(.disabled).active, .show > .btn-custom.dropdown-toggle {
  color: #fff;
  background-color: #097718;
  border-color: #086c16;
}
.btn-custom:not(:disabled):not(.disabled):active:focus, .btn-custom:not(:disabled):not(.disabled).active:focus, .show > .btn-custom.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(48, 180, 67, 0.5);
}

.btn-app {
  color: #212529;
  background-color: #DEF8F4;
  border-color: #DEF8F4;
}
.btn-app:hover {
  color: #212529;
  background-color: #bef1e9;
  border-color: #b4efe6;
}
.btn-app:focus, .btn-app.focus {
  color: #212529;
  background-color: #bef1e9;
  border-color: #b4efe6;
  box-shadow: 0 0 0 0.2rem rgba(194, 216, 214, 0.5);
}
.btn-app.disabled, .btn-app:disabled {
  color: #212529;
  background-color: #DEF8F4;
  border-color: #DEF8F4;
}
.btn-app:not(:disabled):not(.disabled):active, .btn-app:not(:disabled):not(.disabled).active, .show > .btn-app.dropdown-toggle {
  color: #212529;
  background-color: #b4efe6;
  border-color: #a9ede2;
}
.btn-app:not(:disabled):not(.disabled):active:focus, .btn-app:not(:disabled):not(.disabled).active:focus, .show > .btn-app.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(194, 216, 214, 0.5);
}

.btn-sec {
  color: #212529;
  background-color: #e5fcf8;
  border-color: #e5fcf8;
}
.btn-sec:hover {
  color: #212529;
  background-color: #c3f8ef;
  border-color: #b7f7ec;
}
.btn-sec:focus, .btn-sec.focus {
  color: #212529;
  background-color: #c3f8ef;
  border-color: #b7f7ec;
  box-shadow: 0 0 0 0.2rem rgba(200, 220, 217, 0.5);
}
.btn-sec.disabled, .btn-sec:disabled {
  color: #212529;
  background-color: #e5fcf8;
  border-color: #e5fcf8;
}
.btn-sec:not(:disabled):not(.disabled):active, .btn-sec:not(:disabled):not(.disabled).active, .show > .btn-sec.dropdown-toggle {
  color: #212529;
  background-color: #b7f7ec;
  border-color: #acf5e9;
}
.btn-sec:not(:disabled):not(.disabled):active:focus, .btn-sec:not(:disabled):not(.disabled).active:focus, .show > .btn-sec.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(200, 220, 217, 0.5);
}

.btn-link {
  color: #0ca722;
}
.btn-link:focus, .btn-link.focus, .btn-link:hover {
  color: #097718;
}

.btn-link-app {
  color: #DEF8F4;
}
.btn-link-app:focus, .btn-link-app.focus, .btn-link-app:hover {
  color: #b4efe6;
}

.glyphicons {
  vertical-align: baseline;
}
.glyphicons.glyphicons-gaia {
  transform: rotate(90deg);
}
.glyphicons.glyphicons-gaia:before {
  content: "\e174";
}

.noscript {
  visibility: visible;
}
.noscript div.noscript-msg {
  display: block;
  position: absolute;
  z-index: 9999;
  top: 100px;
  left: 50%;
  margin-left: -150px;
  width: 300px;
}

@media (min-width: 576px) {
  .noscript div.noscript-msg {
    margin-left: -200px;
    width: 400px;
  }
}
@font-face {
  font-family: "Ideal Sans SSm A";
  font-style: normal;
  font-weight: 200;
  src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-XLight_Web.woff2") format("woff2"), url("/fonts/IdealSansSSm/woff/IdealSansSSm-XLight_Web.woff") format("woff");
}
@font-face {
  font-family: "Ideal Sans SSm A";
  font-style: italic;
  font-weight: 200;
  src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-XLightItalic_Web.woff2") format("woff2"), url("/fonts/IdealSansSSm/woff/IdealSansSSm-XLightItalic_Web.woff") format("woff");
}
@font-face {
  font-family: "Ideal Sans SSm A";
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-Light_Web.woff2") format("woff2"), url("/fonts/IdealSansSSm/woff/IdealSansSSm-Light_Web.woff") format("woff");
}
@font-face {
  font-family: "Ideal Sans SSm A";
  font-style: italic;
  font-weight: 300;
  src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-LightItalic_Web.woff2") format("woff2"), url("/fonts/IdealSansSSm/woff/IdealSansSSm-LightItalic_Web.woff") format("woff");
}
@font-face {
  font-family: "Ideal Sans SSm A";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-Book_Web.woff2") format("woff2"), url("/fonts/IdealSansSSm/woff/IdealSansSSm-Book_Web.woff") format("woff");
}
@font-face {
  font-family: "Ideal Sans SSm A";
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-BookItalic_Web.woff2") format("woff2"), url("/fonts/IdealSansSSm/woff/IdealSansSSm-BookItalic_Web.woff") format("woff");
}
@font-face {
  font-family: "Ideal Sans SSm A";
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-Medium_Web.woff2") format("woff2"), url("/fonts/IdealSansSSm/woff/IdealSansSSm-Medium_Web.woff") format("woff");
}
@font-face {
  font-family: "Ideal Sans SSm A";
  font-style: italic;
  font-weight: 500;
  src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-MediumItalic_Web.woff2") format("woff2"), url("/fonts/IdealSansSSm/woff/IdealSansSSm-MediumItalic_Web.woff") format("woff");
}
@font-face {
  font-family: "Ideal Sans SSm A";
  font-style: normal;
  font-weight: 600;
  src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-Semibold_Web.woff2") format("woff2"), url("/fonts/IdealSansSSm/woff/IdealSansSSm-Semibold_Web.woff") format("woff");
}
@font-face {
  font-family: "Ideal Sans SSm A";
  font-style: italic;
  font-weight: 600;
  src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-SemiboldItalic_Web.woff2") format("woff2"), url("/fonts/IdealSansSSm/woff/IdealSansSSm-SemiboldItalic_Web.woff") format("woff");
}
@font-face {
  font-family: "Ideal Sans SSm A";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-Bold_Web.woff2") format("woff2"), url("/fonts/IdealSansSSm/woff/IdealSansSSm-Bold_Web.woff") format("woff");
}
@font-face {
  font-family: "Ideal Sans SSm A";
  font-style: italic;
  font-weight: 700;
  src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-BoldItalic_Web.woff2") format("woff2"), url("/fonts/IdealSansSSm/woff/IdealSansSSm-BoldItalic_Web.woff") format("woff");
}
.passage a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off),
.jumbotron a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off),
.paragraph a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off),
.stripe a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off),
.spots a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off),
.accordion a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off),
.usps a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off),
.usps-patient a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off),
.bob-frame .bob-content a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off),
.steps a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off) {
  color: #074b5e;
  text-decoration: none;
  border-bottom: 1px solid #074b5e;
}
.passage a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off):focus,
.jumbotron a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off):focus,
.paragraph a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off):focus,
.stripe a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off):focus,
.spots a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off):focus,
.accordion a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off):focus,
.usps a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off):focus,
.usps-patient a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off):focus,
.bob-frame .bob-content a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off):focus,
.steps a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off):focus {
  outline: 0 none;
  color: black;
  border-bottom: 2px solid #03252f;
  background-color: #12bdec;
}

.footer a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off) {
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid #fff;
}
.footer a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off):focus {
  outline: 0 none;
  background-color: #333;
}

body.bob-frame-open .body-content {
  display: none;
}

.body-content {
  position: relative;
}

.bob-frame {
  display: none;
  overflow: hidden;
  position: relative;
  padding-left: calc(100vw - 100%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100%;
  z-index: 1040;
  background: #ffffff;
}
.bob-frame.bob-fading-in {
  position: fixed;
  top: -1;
  left: 0;
}
.bob-frame .bob-window {
  margin: 0 auto;
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  border: 0 none;
}
.bob-frame .bob-window .bob-header img {
  display: block;
  width: 110px;
  height: auto;
  margin: 10px 0 0 0;
}
.bob-frame .bob-window .bob-content {
  margin-top: 25px;
}

.bob-frame-container {
  padding: 0 30px;
}

@media (min-width: 576px) {
  .bob-frame .bob-window .bob-content {
    margin-top: 30px;
  }
}
@media (min-width: 768px) {
  .bob-frame .bob-window .bob-content {
    margin-top: 40px;
  }
}
.bob-msg {
  display: none;
  position: fixed;
  top: 13px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 1080;
  outline: none;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  cursor: pointer;
}
.bob-msg .msg-content {
  display: none;
  border-radius: 0.3rem;
  padding: 30px 30px 30px 25px;
}
.bob-msg .msg-content::after {
  display: block;
  clear: both;
  content: "";
}
.bob-msg .msg-content i,
.bob-msg .msg-content span.glyphicons {
  float: left;
  width: 40px;
  font-size: 24px;
}
.bob-msg .msg-content span:not(.glyphicons) {
  display: block;
  margin-left: 40px;
  margin-right: 40px;
}
.bob-msg .msg-success i {
  color: #28a745;
}
.bob-msg .msg-success span.glyphicons {
  fill: #28a745;
}
.bob-msg .msg-error i,
.bob-msg .msg-error-checksum i,
.bob-msg .msg-error-request-declined i,
.bob-msg .msg-error-declined i,
.bob-msg .msg-error-expired i,
.bob-msg .msg-error-notfound i,
.bob-msg .msg-error-insurer i {
  color: #dc3545;
}
.bob-msg .msg-error span.glyphicons,
.bob-msg .msg-error-checksum span.glyphicons,
.bob-msg .msg-error-request-declined span.glyphicons,
.bob-msg .msg-error-declined span.glyphicons,
.bob-msg .msg-error-expired span.glyphicons,
.bob-msg .msg-error-notfound span.glyphicons,
.bob-msg .msg-error-insurer span.glyphicons {
  fill: #dc3545;
}
.bob-msg .msg-warning i {
  color: #ffc107;
}
.bob-msg .msg-warning span.glyphicons {
  fill: #ffc107;
}
.bob-msg .msg-warning-access i,
.bob-msg .msg-warning-access span.glyphicons {
  fill: #ffc107;
}

.bob-frame .bob-close-vis,
.bob-msg .bob-close-vis {
  position: absolute;
  top: 13px;
  right: 19px;
  font-size: 40px;
  padding: 4px 0.9rem 8px 0.9rem;
  line-height: 39px;
  border: none;
  border-radius: 50px;
  background-color: #fff;
}
.bob-frame .bob-close-vis:focus, .bob-frame .bob-close-vis:active,
.bob-msg .bob-close-vis:focus,
.bob-msg .bob-close-vis:active {
  outline: 0 none;
  background-color: rgb(230, 230, 230);
  border-radius: 50px;
}
@supports (-moz-appearance: none) {
  .bob-frame .bob-close-vis,
  .bob-msg .bob-close-vis {
    padding: 4px 0.9rem 7px 0.9rem;
    line-height: 42px;
  }
}

body.bob-frame-open .body-content {
  display: none;
}

@media (min-width: 576px) {
  .bob-msg {
    top: 30px;
  }
}
.container-form {
  position: relative;
  padding: 30px;
  min-width: 300px;
  width: 600px;
}

@media (max-width: 991.98px) {
  .container-form {
    width: auto;
  }
}
legend {
  font-size: 30px;
  font-weight: 300;
  line-height: 1.2;
  padding-bottom: 6px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
}

label {
  font-weight: 300;
}

input, select, textarea {
  font-family: "Jost";
  font-style: italic;
}

textarea.form-control {
  resize: vertical;
}

.form-control.error {
  border: 1px solid #ff0000;
}

.form-contact_pro .dateTime,
.form-neuro .dateTime {
  display: none;
}
.form-contact_pro .dateTime.show,
.form-neuro .dateTime.show {
  display: flex;
}

.up-sm,
.up-md,
.up-lg,
.up-xl,
.up-sm-inline,
.up-md-inline,
.up-lg-inline,
.up-xl-inline {
  display: none;
}

.nobr {
  white-space: nowrap;
}

.down-sm {
  display: block;
}

.anchor {
  position: relative;
  top: -80px;
}

@media (min-width: 576px) {
  .down-md {
    display: block;
  }
  .down-md-inline {
    display: inline;
  }
  .up-sm {
    display: block;
  }
  .up-sm-inline {
    display: inline;
  }
  .down-sm,
  .down-sm-inline {
    display: none;
  }
}
@media (min-width: 768px) {
  .down-md,
  .down-md-inline {
    display: none;
  }
  .up-md {
    display: block;
  }
  .up-md-inline {
    display: inline;
  }
  .down-lg {
    display: block;
  }
  .down-lg-inline {
    display: inline;
  }
}
@media (min-width: 992px) {
  .down-lg,
  .down-lg-inline {
    display: none;
  }
  .up-lg {
    display: block;
  }
  .up-lg-inline {
    display: inline;
  }
}
@media (min-width: 1500px) {
  .up-xl {
    display: block;
  }
  .up-xl-inline {
    display: inline;
  }
}
.txt-shadow {
  text-shadow: 0px 0px 1px #333333;
}

.icons {
  display: none;
}

.glyphicons,
.glyphicons-not-copied {
  position: relative;
  top: 1px;
  display: inline-block;
  line-height: 1;
  vertical-align: top;
}
.glyphicons svg,
.glyphicons-not-copied svg {
  margin: 0 auto;
  width: 28px;
  height: 28px;
}

span.glyphicons,
span.glyphicons-not-copied {
  top: 0;
}

.msg-success .glyphicons svg path {
  fill: #28a745;
}

.msg-warning .glyphicons svg path {
  fill: #ffc107;
}

.msg-error .glyphicons svg path {
  fill: #dc3545;
}

#scrolltop {
  position: fixed;
  z-index: 900;
  bottom: 20px;
  right: 20px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  transition: all 0.3s ease;
}
#scrolltop span {
  position: relative;
  transition: all 0.3s ease;
}
#scrolltop i,
#scrolltop span.glyphicons {
  margin: 2px 3px 3px;
  fill: #fff;
}
#scrolltop:focus, #scrolltop:active {
  outline: 0;
  box-shadow: 0 0 0 0.2rem #d1d1d1;
}

#scrolltop:hover {
  text-decoration: none;
}

.boxes {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.box {
  width: 100%;
  padding: 20px 0 35px 0;
  border-radius: 5px;
}
.box .box-inner {
  max-width: 350px;
  width: 80%;
  margin: 0 auto;
}
.box img {
  display: block;
  margin: 0 auto 5px auto;
}

.contact .box ul, .about .box ul {
  padding-left: 36px;
}
.contact .box li, .about .box li {
  list-style: none;
  background: url("/images/check_light.png") no-repeat left 5px;
  margin: 0px 0px 20px -37px;
  padding-left: 40px;
  font-weight: 500;
}

.hero,
.about,
.partner {
  background: rgb(229, 252, 248);
  background: linear-gradient(180deg, rgb(229, 252, 248) 0%, rgb(196, 236, 230) 56%);
}

.box.purple {
  background: rgb(148, 25, 129);
  background: linear-gradient(180deg, rgb(148, 25, 129) 0%, rgb(111, 2, 94) 64%);
}

.box.neutral {
  background: rgb(173, 194, 207);
  background: linear-gradient(180deg, rgb(173, 194, 207) 0%, rgb(138, 162, 174) 64%);
}

.box.green {
  background: rgb(156, 188, 19);
  background: linear-gradient(180deg, rgb(156, 188, 19) 0%, rgb(120, 148, 0) 64%);
}

.usps.dark-green {
  background: rgb(21, 151, 32);
  background: linear-gradient(180deg, rgb(21, 151, 32) 0%, rgb(0, 106, 19) 56%);
}

.item-box.grey {
  background: #a7c6d0;
}

.dark-green {
  background: rgb(68, 197, 1);
  background: linear-gradient(194deg, rgb(68, 197, 1) 37%, rgb(0, 131, 13) 76%);
}

.blue {
  background: rgb(9, 121, 152);
  background: linear-gradient(180deg, rgb(9, 121, 152) 0%, rgb(8, 84, 106) 64%);
}

.contact .box.blue {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.box.orange {
  background: rgb(236, 125, 40);
  background: linear-gradient(180deg, rgb(236, 125, 40) 0%, rgb(186, 93, 22) 64%);
}

.box.blue-dark {
  background: rgb(5, 94, 109);
  background: linear-gradient(180deg, rgb(5, 94, 109) 0%, rgb(0, 65, 76) 64%);
}

@media (min-width: 768px) {
  .boxes {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 50px;
  }
  .boxes .box {
    padding: 30px 0 45px 0;
  }
  .boxes .box .box-inner {
    width: 70%;
  }
  .cta .box {
    width: 100%;
    padding: 50px;
    border-radius: 5px;
  }
}
.bob-style {
  color: #fff;
  background-color: #006162;
  border-color: rgb(145, 145, 145);
  margin: 3px;
  border-radius: 40px;
  border: none;
  font-size: 17px;
  font-weight: bold;
  line-height: 1.5;
  padding: 15px 90px;
}
.bob-style:hover {
  color: #fff;
  background-color: #006162;
  border-color: rgb(146, 146, 146);
}
.bob-style:focus, .bob-style.focus {
  color: #fff;
  background-color: #006162;
  border-color: rgb(146, 146, 146);
  box-shadow: 0 0 0 0.2rem rgba(162, 162, 162, 0.5);
}
.bob-style.disabled, .bob-style:disabled {
  color: #fff;
  background-color: #006162;
  border-color: rgb(145, 145, 145);
}
.bob-style:not(:disabled):not(.disabled):active, .bob-style:not(:disabled):not(.disabled).active, .show > .bob-style.dropdown-toggle {
  color: #fff;
  background-color: #002f2f;
  border-color: #717171;
}
.bob-style:not(:disabled):not(.disabled):active:focus, .bob-style:not(:disabled):not(.disabled).active:focus, .show > .bob-style.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(162, 162, 162, 0.5);
}

.bob-style.small {
  padding: 10px 40px;
}

.cta .bob-style,
.boxes .bob-style {
  color: #212529;
  background-color: #fff;
  border-color: rgb(145, 145, 145);
  width: 100%;
  padding: 12px 12px;
  margin-left: -10px;
}
.cta .bob-style:hover,
.boxes .bob-style:hover {
  color: #212529;
  background-color: #fff;
  border-color: rgb(146, 146, 146);
}
.cta .bob-style:focus, .cta .bob-style.focus,
.boxes .bob-style:focus,
.boxes .bob-style.focus {
  color: #212529;
  background-color: #fff;
  border-color: rgb(146, 146, 146);
  box-shadow: 0 0 0 0.2rem rgba(128, 129, 129, 0.5);
}
.cta .bob-style.disabled, .cta .bob-style:disabled,
.boxes .bob-style.disabled,
.boxes .bob-style:disabled {
  color: #212529;
  background-color: #fff;
  border-color: rgb(145, 145, 145);
}
.cta .bob-style:not(:disabled):not(.disabled):active, .cta .bob-style:not(:disabled):not(.disabled).active, .show > .cta .bob-style.dropdown-toggle,
.boxes .bob-style:not(:disabled):not(.disabled):active,
.boxes .bob-style:not(:disabled):not(.disabled).active,
.show > .boxes .bob-style.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #717171;
}
.cta .bob-style:not(:disabled):not(.disabled):active:focus, .cta .bob-style:not(:disabled):not(.disabled).active:focus, .show > .cta .bob-style.dropdown-toggle:focus,
.boxes .bob-style:not(:disabled):not(.disabled):active:focus,
.boxes .bob-style:not(:disabled):not(.disabled).active:focus,
.show > .boxes .bob-style.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 129, 129, 0.5);
}

@media (min-width: 768px) {
  .cta .bob-style {
    max-width: 300px;
  }
}
.bob-style-bordered {
  border-color: rgb(146, 146, 146);
  -webkit-box-shadow: 0 0 0 0.2rem rgba(162, 162, 162, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(162, 162, 162, 0.5);
}

.container-big {
  width: 100%;
  padding: 0 10px;
  margin: 0 auto;
  overflow: hidden;
}

@media (min-width: 576px) {
  .container-big {
    max-width: 540px;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .container-big {
    max-width: 780px;
  }
  .contact .container-big {
    padding: 0 40px;
  }
}
@media (min-width: 992px) {
  .container-big {
    max-width: 1000px;
  }
  .contact .container-big {
    padding: 0;
  }
}
@media (min-width: 1500px) {
  .container-big {
    max-width: 1200px;
  }
}
.bob-frame.contains-w1-bob-wizard-form1 .content p,
.bob-frame.contains-w1-bob-wizard-form1 .form-group-email p,
.bob-frame.contains-w2-bob-wizard-form2 .content p,
.bob-frame.contains-w2-bob-wizard-form2 .form-group-email p,
.bob-frame.contains-c1-form-contact_pro .content p,
.bob-frame.contains-c1-form-contact_pro .form-group-email p,
.bob-frame.contains-w3-bob-wizard-form3 .content p,
.bob-frame.contains-w3-bob-wizard-form3 .form-group-email p,
.bob-frame.contains-w4-bob-wizard-form4 .content p,
.bob-frame.contains-w4-bob-wizard-form4 .form-group-email p,
.bob-frame.contains-e1-error-frame .content p,
.bob-frame.contains-e1-error-frame .form-group-email p,
.bob-frame.contains-s2-bob-wizard-success2 .content p,
.bob-frame.contains-s2-bob-wizard-success2 .form-group-email p,
.bob-frame.contains-s1-bob-wizard-success1 .content p,
.bob-frame.contains-s1-bob-wizard-success1 .form-group-email p {
  font-size: 15px;
  font-weight: 300;
}

.form-contact_pro .form-group-description textarea {
  display: none;
}
.form-contact_pro .form-group-description.show textarea {
  display: flex;
}
.form-contact_pro legend {
  font-size: 21px;
}
.form-contact_pro .small {
  font-size: 14px;
}
.form-contact_pro .font-weight-500 {
  font-weight: 500;
}

.is-invalid .radio label {
  color: #dc3545;
}
.is-invalid .custom-control-label {
  color: #dc3545;
}

.bob-frame.contains-w2-bob-wizard-form2 .custom-control-label {
  font-size: 15px;
  font-weight: 300;
}

.form-group-email label {
  white-space: nowrap;
}

.bob-frame.contains-w1-bob-wizard-form1 legend {
  font-size: 23px;
}

@media (min-width: 576px) {
  .bob-frame.contains-bob-frame-content-passage .passage {
    padding-top: 0;
  }
  .bob-frame.contains-bob-frame-content-passage .passage .container {
    padding: 0;
  }
  .bob-frame.contains-bob-frame-content-passage .bob-window {
    max-width: 750px;
  }
  .bob-frame.contains-w1-bob-wizard-form1 legend {
    font-size: 30px;
  }
}
@media (min-width: 768px) {
  .bob-frame.contains-w1-bob-wizard-form1 .bob-window,
  .bob-frame.contains-w2-bob-wizard-form2 .bob-window,
  .bob-frame.contains-c1-form-contact_pro .bob-window,
  .bob-frame.contains-w3-bob-wizard-form3 .bob-window,
  .bob-frame.contains-w4-bob-wizard-form4 .bob-window,
  .bob-frame.contains-e1-error-frame .bob-window,
  .bob-frame.contains-s2-bob-wizard-success2 .bob-window,
  .bob-frame.contains-s1-bob-wizard-success1 .bob-window {
    width: 600px;
  }
}
.bob-frame.contains-bob-frame-content-passage .bob-window .bob-content p, .bob-frame.contains-bob-frame-content-passage .bob-window .bob-content .passge-frame-header {
  margin: 20px 0;
}

@media (min-width: 768px) {
  .bob-frame.contains-bob-frame-content-passage .bob-window .bob-content p, .bob-frame.contains-bob-frame-content-passage .bob-window .bob-content .passge-frame-header {
    margin: 25px 0;
  }
}
@media (min-width: 992px) {
  .bob-frame.contains-bob-frame-content-passage .bob-window .bob-content p, .bob-frame.contains-bob-frame-content-passage .bob-window .bob-content .passge-frame-header {
    margin: 30px 0;
  }
}
@media (min-width: 1500px) {
  .bob-frame.contains-bob-frame-content-passage .bob-window .bob-content p, .bob-frame.contains-bob-frame-content-passage .bob-window .bob-content .passge-frame-header {
    margin: 35px 0;
  }
}
body {
  font-feature-settings: "lnum" 1;
}

@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Jost/woff2/jost-v6-latin-300.woff2") format("woff2") url("/fonts/Jost/woff/jost-v6-latin-300.woff") format("woff");
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Jost/woff2/jost-v6-latin-regular.woff2") format("woff2"), url("/fonts/Jost/woff/jost-v6-latin-regular.woff") format("woff");
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/Jost/woff2/jost-v6-latin-500.woff2") format("woff2"), url("/fonts/Jost/woff/jost-v6-latin-500.woff") format("woff");
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  src: url("/fonts/Jost/woff2/jost-v6-latin-600.woff2") format("woff2"), url("/fonts/Jost/woff/jost-v6-latin-600.woff") format("woff");
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Jost/woff2/jost-v6-latin-700.woff2") format("woff2"), url("/fonts/Jost/woff/jost-v6-latin-700.woff") format("woff");
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/Jost/woff2/jost-v6-latin-italic.woff2") format("woff2"), url("/fonts/Jost/woff/jost-v6-latin-italic.woff") format("woff");
}
/* jost-800 - latin */
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 800;
  src: url("/fonts/Jost/woff2/jost-v13-latin-800.woff2") format("woff2"), url("/fonts/Jost/woff/jost-v13-latin-800.woff") format("woff");
}
/* jost-900 - latin */
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Jost/woff2/jost-v13-latin-900.woff2") format("woff2"), url("/fonts/Jost/woff/jost-v13-latin-900.woff") format("woff");
}
/* jost-800italic - latin */
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 800;
  src: url("/fonts/Jost/woff2/jost-v13-latin-800italic.woff2") format("woff2"), url("/fonts/Jost/woff/jost-v13-latin-800italic.woff") format("woff");
}
/* jost-900italic - latin */
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 900;
  src: url("/fonts/Jost/woff2/jost-v13-latin-900italic.woff2") format("woff2"), url("/fonts/Jost/woff/jost-v13-latin-900italic.woff") format("woff");
}
.footer {
  padding-bottom: 50px;
  padding-top: 100px;
  color: #fff;
  font-size: 15px;
  background: #0A2438;
}
.footer span {
  color: #73B7D1;
  font-weight: 600;
}
.footer hr {
  margin: 70px 0;
  width: 100%;
  border: 0;
  height: 0;
  border-top: 1px solid #DEF8F4;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.footer .footer-column {
  padding-top: 50px;
  color: #fff;
}
.footer .footer-column a,
.footer .footer-column a:hover {
  text-decoration: none;
  color: #fff;
}
.footer .footer-column a {
  opacity: 0.7;
}
.footer .footer-column a:hover,
.footer .footer-column a.active {
  opacity: 1;
}
.footer .footer-column ul {
  list-style: none;
  padding-left: 0;
}
.footer .footer-column ul li {
  position: relative;
  padding-left: 13px;
}
.footer .footer-column ul .bullet {
  display: block;
  position: absolute;
  top: 11px;
  left: 0px;
  background: #fff;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  opacity: 0.5;
}
.footer .footer-logo {
  height: 100px;
}
.footer .footer-logo .gaia-logo {
  margin-top: 16px;
}
.footer .footer-legal {
  line-height: 1.3;
}
.footer .footer-legal span {
  color: #fff;
  white-space: nowrap;
  font-weight: 400;
}
.footer .footer-formal-links {
  margin-top: 14px;
}

.footer .footer-links .container {
  overflow: visible;
}

.footer-redirect {
  display: none;
}

.footer .footer-col-mobile h3 {
  font-size: 30px;
}
.footer .footer-col-mobile h3,
.footer .footer-col-mobile p {
  width: 60%;
  margin: 0 auto;
}
.footer .footer-col-mobile p {
  padding-top: 40px;
}
.footer .footer-col-mobile p:before {
  content: "";
  margin-bottom: 50px;
  width: 100%;
  height: 1px;
  background-color: rgb(177, 177, 177);
  display: block;
}
.footer .footer-col-mobile p a {
  opacity: 1;
}

@media (min-width: 576px) {
  .footer .footer-col-mobile h3,
  .footer .footer-col-mobile p {
    width: 100%;
    margin: 0;
  }
  .footer .footer-col-mobile p:before {
    display: none;
  }
  .footer .footer-col-mobile p a {
    opacity: 0.7;
  }
}
@media (min-width: 768px) {
  .footer .footer-col-mobile {
    display: table;
    margin: 0 0 0 auto;
  }
}
@media (min-width: 992px) {
  .footer {
    padding-bottom: 100px;
  }
}
.navbar a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off) {
  color: #074b5e;
  text-decoration: none;
  border-bottom: 1px solid #074b5e;
}
.navbar a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off):focus {
  outline: 0 none;
  color: black;
  border-bottom: 2px solid #03252f;
  background-color: #12bdec;
}

.footer a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link) {
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid #fff;
}
.footer a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):focus {
  outline: 0 none;
  background-color: #333;
}

.horizontal-scroll {
  overflow: auto;
  display: flex;
  position: static;
  opacity: 0;
}

.illustration {
  scroll-snap-align: start;
}

.horizontal-scroll.animate-show {
  opacity: 1;
  -webkit-animation: ease-in;
  -webkit-animation-name: run;
  -webkit-animation-duration: 1.5s;
}

@-webkit-keyframes run {
  0% {
    position: absolute;
    left: 95vw;
  }
  99% {
    left: -90vw;
  }
  100% {
    position: static;
    scroll-snap-type: x mandatory;
  }
}
@media (min-width: 992px) {
  .circles .horizontal-scroll {
    display: none;
  }
}
.circles {
  padding-bottom: 20px;
  height: 400px;
}
.circles h1 {
  z-index: 1;
  background: -webkit-linear-gradient(#18d534, #064a0f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.circles .f-1 {
  font-size: 26px;
  line-height: 1.1;
  font-weight: 500;
  margin-bottom: -9px;
}
.circles .f-2 {
  font-size: 34px;
  font-weight: 800;
  line-height: 1.5;
}
.circles .f-1.f-1-upper {
  margin-bottom: -9px;
}
.circles .f-2.f-2-upper {
  margin-bottom: -20px;
}
.circles .f-1.f-1-lower {
  margin-top: -4px;
}
.circles .f-1.f-2-lower {
  margin-bottom: -20px;
}
.circles h2 {
  font-size: 24px;
  line-height: 1.2;
}
.circles p.h4,
.circles div.h4 {
  font-size: 18px;
}
.circles div.h4 {
  font-weight: 800;
}
.circles p .color-app {
  color: #bbfffc;
}
.circles h2, .circles h3, .circles strong {
  font-weight: 700;
  color: #074b5e;
}
.circles h2.green, .circles h3.green {
  color: #00b372;
}
.circles h3 {
  font-size: 22px;
}
.circles div.h4 {
  color: #fff;
}
.circles p.extra {
  font-size: 28px;
  font-weight: 900;
  font-style: italic;
  line-height: 1.2;
}
.circles span.dark {
  color: #074b5e;
}
.circles span.bold {
  font-weight: 700;
}
.circles p.white {
  color: #fff;
}
@media only screen and (min-width: 430px) {
  .circles .f-1 {
    font-size: 28px;
  }
  .circles .f-2 {
    font-size: 38px;
  }
}
@media only screen and (min-width: 500px) {
  .circles .f-1 {
    font-size: 38px;
  }
  .circles .f-2 {
    font-size: 48px;
  }
  .circles p.extra {
    font-size: 32px;
  }
}
@media (min-width: 576px) {
  .circles h2 {
    font-size: 26px;
  }
}
@media (min-width: 768px) {
  .circles .f-1 {
    font-size: 40px;
  }
  .circles .f-2 {
    font-size: 55px;
  }
  .circles h1 {
    background: -webkit-linear-gradient(#15c22f, #0b871c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .circles h2 {
    font-size: 38px;
  }
  .circles h3 {
    font-size: 26px;
  }
  .circles p.extra {
    font-size: 34px;
  }
  .circles p.h4,
  .circles div.h4 {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .circles .f-1 {
    font-size: 55px;
  }
  .circles .f-2 {
    font-size: 68px;
  }
  .circles h2 {
    font-size: 42px;
  }
  .circles h2.lg-small {
    font-size: 38px;
  }
  .circles h3 {
    font-size: 28px;
  }
}
@media (min-width: 768px) {
  .circles {
    padding-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .circles {
    padding-bottom: 40px;
  }
}

@media (min-width: 992px) {
  .circles {
    height: auto;
  }
  .circles .illustration {
    width: 32%;
  }
  .circles .illustration img {
    width: 100%;
  }
}
.cta #col-cta-contact {
  font-weight: 500;
}
.cta #cta-heading {
  font-size: 2rem;
  line-height: 1.2;
}
.cta #cont-contact-emergency {
  font-size: 17px;
}
.cta #cont-contact-emergency a {
  color: #fff;
  text-decoration: none;
}
.cta .emergency-green-gradient {
  background: linear-gradient(161deg, #64be37 0%, #398b3d 100%);
}
.cta .emergency-blue-gradient {
  background: linear-gradient(194deg, #074f63 37%, #031f27 76%);
}

.navbar {
  background-color: #DEF8F4;
  top: 0;
  padding: 0 0.1rem;
  transition: padding-top 0.3s, margin-top 0.3s;
}
.navbar .container {
  overflow: visible;
  transition: margin-top 0.3s;
  margin-top: 33px;
  padding-bottom: 30px;
}
.navbar .navbar-brand:focus {
  background-color: transparent !important;
}

.navbar-logo-outer {
  width: 100px;
}

.navbar-logo {
  transition: width 0.3s, margin 0.3s;
  max-height: 70px;
  width: 100%;
  height: auto;
}

.nav-item .dropdown-menu {
  background-color: #def8f4;
  border: none;
}
.nav-item .dropdown-item {
  border: none !important;
}
.nav-item .dropdown-item:active, .nav-item .dropdown-item:focus, .nav-item .dropdown-item:hover {
  background-color: transparent !important;
}

.nav-link {
  font-size: 12px;
  font-weight: 500;
  color: #074b5e;
}
.nav-link img {
  margin-top: -6px;
}
.nav-link span {
  padding-top: 8px;
}

.nav-link {
  color: rgba(0, 0, 0, 0.5) !important;
}

.nav-link.activeItem {
  color: #106868 !important;
}

.navbar.navbar-animate {
  padding-top: 0px;
  margin-top: -160px;
}
.navbar.navbar-animate .container {
  margin-top: 0;
}

.navbar-nav {
  display: block;
}

.navbar-nav .dropdown-toggle::after {
  display: none;
}

@media only screen and (min-width: 400px) {
  .navbar {
    padding: 0 2rem;
  }
}
@media (min-width: 576px) {
  .navbar .container {
    padding-bottom: 0px;
  }
  .navbar-logo-outer {
    width: 130px;
  }
  .navbar-nav {
    display: flex;
    margin-bottom: 0px;
  }
  .navbar.navbar-animate {
    padding-top: 0px;
    margin-top: 0;
  }
  .navbar.navbar-animate .navbar-logo {
    width: 90%;
    margin: 5px 0;
  }
  .navbar.navbar-animate .container {
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .navbar-logo {
    width: 100%;
  }
  .nav-item a {
    font-size: 17px;
  }
}
.body-content {
  overflow: hidden;
}

.theNumber {
  position: absolute;
  top: -26px;
  left: -16px;
  max-width: 135px;
  display: grid;
  grid-template-columns: 11.25px 90px 1fr;
  grid-template-rows: 50px 200px 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  z-index: 2;
}
.theNumber .number-title {
  grid-area: 2/2/3/3;
}
.theNumber .number-image {
  grid-area: 1/1/4/4;
}
.theNumber .number-title {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  color: #074b5e;
  font-size: 13px;
  white-space: pre;
  opacity: 0.5;
}
.theNumber .number-title span {
  padding: 0 15px;
  border-top: solid 1px;
  border-left: solid 1px;
}
.theNumber .number-image {
  width: 100%;
}
.theNumber .number-image img {
  width: 100%;
}

.theNumber {
  left: -25px;
}

.theNumber.right {
  top: -120px;
  left: auto;
  right: -15px;
}

.theNumber.number-top {
  top: 65px;
}

@media (min-width: 768px) {
  .theNumber {
    top: -120px;
    max-width: 235px;
    grid-template-columns: 19.5833333333px 156.6666666667px 1fr;
  }
}
@media (min-width: 1500px) {
  .theNumber {
    max-width: 335px;
    grid-template-columns: 27.9166666667px 223.3333333333px 1fr;
    left: -20px;
  }
}
.about {
  padding-top: 20px;
  padding-bottom: 40px;
  background-color: #DEF8F4;
  position: relative;
}
.about h1 {
  z-index: 1;
  background: -webkit-linear-gradient(#18d534, #064a0f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about .f-1 {
  font-size: 26px;
  line-height: 1.1;
  font-weight: 500;
  margin-bottom: -9px;
}
.about .f-2 {
  font-size: 34px;
  font-weight: 800;
  line-height: 1.5;
}
.about .f-1.f-1-upper {
  margin-bottom: -9px;
}
.about .f-2.f-2-upper {
  margin-bottom: -20px;
}
.about .f-1.f-1-lower {
  margin-top: -4px;
}
.about .f-1.f-2-lower {
  margin-bottom: -20px;
}
.about h2 {
  font-size: 24px;
  line-height: 1.2;
}
.about p.h4,
.about div.h4 {
  font-size: 18px;
}
.about div.h4 {
  font-weight: 800;
}
.about p .color-app {
  color: #bbfffc;
}
.about h2, .about h3, .about strong {
  font-weight: 700;
  color: #074b5e;
}
.about h2.green, .about h3.green {
  color: #00b372;
}
.about h3 {
  font-size: 22px;
}
.about div.h4 {
  color: #fff;
}
.about p.extra {
  font-size: 28px;
  font-weight: 900;
  font-style: italic;
  line-height: 1.2;
}
.about span.dark {
  color: #074b5e;
}
.about span.bold {
  font-weight: 700;
}
.about p.white {
  color: #fff;
}
@media only screen and (min-width: 430px) {
  .about .f-1 {
    font-size: 28px;
  }
  .about .f-2 {
    font-size: 38px;
  }
}
@media only screen and (min-width: 500px) {
  .about .f-1 {
    font-size: 38px;
  }
  .about .f-2 {
    font-size: 48px;
  }
  .about p.extra {
    font-size: 32px;
  }
}
@media (min-width: 576px) {
  .about h2 {
    font-size: 26px;
  }
}
@media (min-width: 768px) {
  .about .f-1 {
    font-size: 40px;
  }
  .about .f-2 {
    font-size: 55px;
  }
  .about h1 {
    background: -webkit-linear-gradient(#15c22f, #0b871c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .about h2 {
    font-size: 38px;
  }
  .about h3 {
    font-size: 26px;
  }
  .about p.extra {
    font-size: 34px;
  }
  .about p.h4,
  .about div.h4 {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .about .f-1 {
    font-size: 55px;
  }
  .about .f-2 {
    font-size: 68px;
  }
  .about h2 {
    font-size: 42px;
  }
  .about h2.lg-small {
    font-size: 38px;
  }
  .about h3 {
    font-size: 28px;
  }
}
@media (min-width: 768px) {
  .about {
    padding-top: 30px;
  }
}
@media (min-width: 992px) {
  .about {
    padding-top: 40px;
  }
}
@media (min-width: 768px) {
  .about {
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .about {
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .about h2 {
    font-size: 55px !important;
  }
}
.about .text ul {
  padding-left: 36px;
}
.about .text li, .about #program-content li {
  list-style: none;
  background: url("/images/check_dark.png") no-repeat left 5px;
  margin: 0px 0px 20px -37px;
  padding-left: 36px;
  font-weight: 500;
}

.about-veovita {
  padding-top: 100px;
}

@media (min-width: 992px) {
  .about-veovita {
    padding-top: 65px;
  }
}
.hero {
  padding-top: 120px;
  padding-bottom: 30px;
}
.hero h1 {
  z-index: 1;
  background: -webkit-linear-gradient(#18d534, #064a0f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.hero .f-1 {
  font-size: 26px;
  line-height: 1.1;
  font-weight: 500;
  margin-bottom: -9px;
}
.hero .f-2 {
  font-size: 34px;
  font-weight: 800;
  line-height: 1.5;
}
.hero .f-1.f-1-upper {
  margin-bottom: -9px;
}
.hero .f-2.f-2-upper {
  margin-bottom: -20px;
}
.hero .f-1.f-1-lower {
  margin-top: -4px;
}
.hero .f-1.f-2-lower {
  margin-bottom: -20px;
}
.hero h2 {
  font-size: 24px;
  line-height: 1.2;
}
.hero p.h4,
.hero div.h4 {
  font-size: 18px;
}
.hero div.h4 {
  font-weight: 800;
}
.hero p .color-app {
  color: #bbfffc;
}
.hero h2, .hero h3, .hero strong {
  font-weight: 700;
  color: #074b5e;
}
.hero h2.green, .hero h3.green {
  color: #00b372;
}
.hero h3 {
  font-size: 22px;
}
.hero div.h4 {
  color: #fff;
}
.hero p.extra {
  font-size: 28px;
  font-weight: 900;
  font-style: italic;
  line-height: 1.2;
}
.hero span.dark {
  color: #074b5e;
}
.hero span.bold {
  font-weight: 700;
}
.hero p.white {
  color: #fff;
}
@media only screen and (min-width: 430px) {
  .hero .f-1 {
    font-size: 28px;
  }
  .hero .f-2 {
    font-size: 38px;
  }
}
@media only screen and (min-width: 500px) {
  .hero .f-1 {
    font-size: 38px;
  }
  .hero .f-2 {
    font-size: 48px;
  }
  .hero p.extra {
    font-size: 32px;
  }
}
@media (min-width: 576px) {
  .hero h2 {
    font-size: 26px;
  }
}
@media (min-width: 768px) {
  .hero .f-1 {
    font-size: 40px;
  }
  .hero .f-2 {
    font-size: 55px;
  }
  .hero h1 {
    background: -webkit-linear-gradient(#15c22f, #0b871c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .hero h2 {
    font-size: 38px;
  }
  .hero h3 {
    font-size: 26px;
  }
  .hero p.extra {
    font-size: 34px;
  }
  .hero p.h4,
  .hero div.h4 {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .hero .f-1 {
    font-size: 55px;
  }
  .hero .f-2 {
    font-size: 68px;
  }
  .hero h2 {
    font-size: 42px;
  }
  .hero h2.lg-small {
    font-size: 38px;
  }
  .hero h3 {
    font-size: 28px;
  }
}
.hero img {
  max-width: 100%;
}
.hero p {
  padding-top: 13.3333333333px;
  padding-bottom: 13.3333333333px;
  font-weight: 500;
  max-width: 570px;
}
@media (min-width: 768px) {
  .hero p {
    padding-top: 20px;
  }
}
@media (min-width: 992px) {
  .hero p {
    padding-top: 26.6666666667px;
  }
}
@media (min-width: 768px) {
  .hero p {
    padding-bottom: 20px;
  }
}
@media (min-width: 992px) {
  .hero p {
    padding-bottom: 26.6666666667px;
  }
}
.hero .h1-bounded {
  max-width: 700px;
}
.hero .content-box {
  display: grid;
  grid-template-columns: 8fr 1fr;
  grid-template-rows: 1fr 4fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
}
.hero .content-box h1 {
  line-height: 1.2;
  grid-area: 2/1/3/2;
}
.hero .content-box img {
  grid-area: 1/1/3/3;
}

.hero-employer {
  padding-top: 80px;
}
.hero-employer h1 {
  font-size: 2.3rem;
}

.hero-insurance {
  padding-top: 140px;
}

.hero-employer .img-mb-bottom, .hero-insurance .img-mb-bottom {
  padding-top: 160px;
}

@media only screen and (min-width: 400px) {
  .hero .content {
    padding: 0 2rem;
  }
}
@media only screen and (min-width: 500px) {
  .hero .content-box-patient h1 {
    margin-top: 50px;
  }
  .hero-insurance h1 {
    font-size: 2.3rem;
  }
}
@media (min-width: 576px) {
  .hero .content {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .hero {
    height: 750px;
    padding-top: 150px;
  }
  .hero p {
    max-width: 400px;
  }
  .hero .content {
    z-index: 2;
    position: relative;
    padding-top: 10px;
  }
  .hero h1 {
    margin-top: 0px !important;
    margin-left: 3%;
    font-size: 3.5rem !important;
  }
  .hero .content-box {
    display: block;
  }
  .hero .content-box-patient h1 {
    font-size: 2.7rem !important;
  }
  .hero .f-1,
  .hero p {
    margin-left: 3%;
  }
  .hero img {
    position: absolute;
    top: 100px;
    right: 0;
    z-index: 1;
  }
  .hero img.img-pd {
    height: 500px;
  }
  .hero img.img-sub {
    height: 400px;
  }
  .hero-insurance h1 {
    font-size: 3.3rem !important;
  }
}
@media (min-width: 992px) {
  .hero .container {
    margin-top: 0;
  }
  .hero .content {
    padding-top: 0px;
  }
  .hero .content-box-patient {
    max-width: 75%;
  }
  .hero .content-box-patient h1 {
    font-size: 3.5rem !important;
  }
  .hero p {
    max-width: 500px;
  }
  .hero img {
    right: 8%;
  }
  .hero-insurance h1 {
    font-size: 3.5rem !important;
  }
}
@media (min-width: 1500px) {
  .hero p {
    max-width: 570px;
  }
  .hero img {
    right: 15%;
  }
  .hero img.img-pd {
    height: 678px;
  }
  .hero img.img-sub {
    height: 600px;
  }
}
.contact {
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
}
.contact h1 {
  z-index: 1;
  background: -webkit-linear-gradient(#18d534, #064a0f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contact .f-1 {
  font-size: 26px;
  line-height: 1.1;
  font-weight: 500;
  margin-bottom: -9px;
}
.contact .f-2 {
  font-size: 34px;
  font-weight: 800;
  line-height: 1.5;
}
.contact .f-1.f-1-upper {
  margin-bottom: -9px;
}
.contact .f-2.f-2-upper {
  margin-bottom: -20px;
}
.contact .f-1.f-1-lower {
  margin-top: -4px;
}
.contact .f-1.f-2-lower {
  margin-bottom: -20px;
}
.contact h2 {
  font-size: 24px;
  line-height: 1.2;
}
.contact p.h4,
.contact div.h4 {
  font-size: 18px;
}
.contact div.h4 {
  font-weight: 800;
}
.contact p .color-app {
  color: #bbfffc;
}
.contact h2, .contact h3, .contact strong {
  font-weight: 700;
  color: #074b5e;
}
.contact h2.green, .contact h3.green {
  color: #00b372;
}
.contact h3 {
  font-size: 22px;
}
.contact div.h4 {
  color: #fff;
}
.contact p.extra {
  font-size: 28px;
  font-weight: 900;
  font-style: italic;
  line-height: 1.2;
}
.contact span.dark {
  color: #074b5e;
}
.contact span.bold {
  font-weight: 700;
}
.contact p.white {
  color: #fff;
}
@media only screen and (min-width: 430px) {
  .contact .f-1 {
    font-size: 28px;
  }
  .contact .f-2 {
    font-size: 38px;
  }
}
@media only screen and (min-width: 500px) {
  .contact .f-1 {
    font-size: 38px;
  }
  .contact .f-2 {
    font-size: 48px;
  }
  .contact p.extra {
    font-size: 32px;
  }
}
@media (min-width: 576px) {
  .contact h2 {
    font-size: 26px;
  }
}
@media (min-width: 768px) {
  .contact .f-1 {
    font-size: 40px;
  }
  .contact .f-2 {
    font-size: 55px;
  }
  .contact h1 {
    background: -webkit-linear-gradient(#15c22f, #0b871c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .contact h2 {
    font-size: 38px;
  }
  .contact h3 {
    font-size: 26px;
  }
  .contact p.extra {
    font-size: 34px;
  }
  .contact p.h4,
  .contact div.h4 {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .contact .f-1 {
    font-size: 55px;
  }
  .contact .f-2 {
    font-size: 68px;
  }
  .contact h2 {
    font-size: 42px;
  }
  .contact h2.lg-small {
    font-size: 38px;
  }
  .contact h3 {
    font-size: 28px;
  }
}
@media (min-width: 768px) {
  .contact {
    padding-top: 60px;
  }
}
@media (min-width: 992px) {
  .contact {
    padding-top: 80px;
  }
}
@media (min-width: 768px) {
  .contact {
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .contact {
    padding-bottom: 80px;
  }
}
@media only screen and (min-width: 500px) {
  .contact h2 {
    max-width: 80%;
  }
}
@media (min-width: 992px) {
  .contact h2 {
    font-size: 55px !important;
  }
}
.contact .content p {
  padding-bottom: 20px;
}
@media (min-width: 768px) {
  .contact .content p {
    padding-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .contact .content p {
    padding-bottom: 40px;
  }
}

.career {
  position: relative;
}
.career img.icon {
  height: 300px;
  padding-top: 40px;
}
.career .bg-light-blue {
  background-color: #e7fefa;
}
.career .custom-blue {
  color: #105465;
}
.career .custom-h3 {
  font-size: 35px;
}
.career ul {
  padding-left: 36px;
}
.career li {
  list-style: none;
  background: url("/images/check_dark.png") no-repeat left 5px;
  margin: 0px 0px 20px -37px;
  padding-left: 36px;
  font-weight: 500;
}
@media only screen and (min-width: 500px) {
  .career #contact-info {
    font-size: 1.4rem;
  }
}
.career .arrow-job-link {
  font-size: 45px;
  color: #155c67;
}
@media (min-width: 992px) {
  .career .arrow-job-link {
    font-size: 60px;
  }
}
.career #jobs a {
  text-decoration: none;
}

.services {
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
}
.services h1 {
  z-index: 1;
  background: -webkit-linear-gradient(#18d534, #064a0f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.services .f-1 {
  font-size: 26px;
  line-height: 1.1;
  font-weight: 500;
  margin-bottom: -9px;
}
.services .f-2 {
  font-size: 34px;
  font-weight: 800;
  line-height: 1.5;
}
.services .f-1.f-1-upper {
  margin-bottom: -9px;
}
.services .f-2.f-2-upper {
  margin-bottom: -20px;
}
.services .f-1.f-1-lower {
  margin-top: -4px;
}
.services .f-1.f-2-lower {
  margin-bottom: -20px;
}
.services h2 {
  font-size: 24px;
  line-height: 1.2;
}
.services p.h4,
.services div.h4 {
  font-size: 18px;
}
.services div.h4 {
  font-weight: 800;
}
.services p .color-app {
  color: #bbfffc;
}
.services h2, .services h3, .services strong {
  font-weight: 700;
  color: #074b5e;
}
.services h2.green, .services h3.green {
  color: #00b372;
}
.services h3 {
  font-size: 22px;
}
.services div.h4 {
  color: #fff;
}
.services p.extra {
  font-size: 28px;
  font-weight: 900;
  font-style: italic;
  line-height: 1.2;
}
.services span.dark {
  color: #074b5e;
}
.services span.bold {
  font-weight: 700;
}
.services p.white {
  color: #fff;
}
@media only screen and (min-width: 430px) {
  .services .f-1 {
    font-size: 28px;
  }
  .services .f-2 {
    font-size: 38px;
  }
}
@media only screen and (min-width: 500px) {
  .services .f-1 {
    font-size: 38px;
  }
  .services .f-2 {
    font-size: 48px;
  }
  .services p.extra {
    font-size: 32px;
  }
}
@media (min-width: 576px) {
  .services h2 {
    font-size: 26px;
  }
}
@media (min-width: 768px) {
  .services .f-1 {
    font-size: 40px;
  }
  .services .f-2 {
    font-size: 55px;
  }
  .services h1 {
    background: -webkit-linear-gradient(#15c22f, #0b871c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .services h2 {
    font-size: 38px;
  }
  .services h3 {
    font-size: 26px;
  }
  .services p.extra {
    font-size: 34px;
  }
  .services p.h4,
  .services div.h4 {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .services .f-1 {
    font-size: 55px;
  }
  .services .f-2 {
    font-size: 68px;
  }
  .services h2 {
    font-size: 42px;
  }
  .services h2.lg-small {
    font-size: 38px;
  }
  .services h3 {
    font-size: 28px;
  }
}
@media (min-width: 768px) {
  .services {
    padding-top: 60px;
  }
}
@media (min-width: 992px) {
  .services {
    padding-top: 80px;
  }
}
@media (min-width: 768px) {
  .services {
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .services {
    padding-bottom: 80px;
  }
}
.services a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link) {
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid #fff;
}
.services a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):focus {
  outline: 0 none;
  background-color: #074b5e;
}
.services h2 {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .services h2 {
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .services h2 {
    margin-bottom: 40px;
  }
}
.services .box img {
  width: 100%;
  max-height: 55px;
}
.services .box .box-images {
  display: flex;
  justify-content: space-between;
  max-width: 350px;
  width: 80%;
  margin: 0 auto;
}
.services .box .box-images img {
  margin: 0;
  width: 40%;
}
@media (min-width: 768px) {
  .services .box .box-images {
    width: 70%;
  }
}
@media (min-width: 992px) {
  .services .box .box-images {
    width: 80%;
  }
}

.usps {
  padding-top: 20px;
  padding-bottom: 40px;
  position: relative;
  background-color: #0ca722;
}
.usps h1 {
  z-index: 1;
  background: -webkit-linear-gradient(#18d534, #064a0f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.usps .f-1 {
  font-size: 26px;
  line-height: 1.1;
  font-weight: 500;
  margin-bottom: -9px;
}
.usps .f-2 {
  font-size: 34px;
  font-weight: 800;
  line-height: 1.5;
}
.usps .f-1.f-1-upper {
  margin-bottom: -9px;
}
.usps .f-2.f-2-upper {
  margin-bottom: -20px;
}
.usps .f-1.f-1-lower {
  margin-top: -4px;
}
.usps .f-1.f-2-lower {
  margin-bottom: -20px;
}
.usps h2 {
  font-size: 24px;
  line-height: 1.2;
}
.usps p.h4,
.usps div.h4 {
  font-size: 18px;
}
.usps div.h4 {
  font-weight: 800;
}
.usps p .color-app {
  color: #bbfffc;
}
.usps h2, .usps h3, .usps strong {
  font-weight: 700;
  color: #074b5e;
}
.usps h2.green, .usps h3.green {
  color: #00b372;
}
.usps h3 {
  font-size: 22px;
}
.usps div.h4 {
  color: #fff;
}
.usps p.extra {
  font-size: 28px;
  font-weight: 900;
  font-style: italic;
  line-height: 1.2;
}
.usps span.dark {
  color: #074b5e;
}
.usps span.bold {
  font-weight: 700;
}
.usps p.white {
  color: #fff;
}
@media only screen and (min-width: 430px) {
  .usps .f-1 {
    font-size: 28px;
  }
  .usps .f-2 {
    font-size: 38px;
  }
}
@media only screen and (min-width: 500px) {
  .usps .f-1 {
    font-size: 38px;
  }
  .usps .f-2 {
    font-size: 48px;
  }
  .usps p.extra {
    font-size: 32px;
  }
}
@media (min-width: 576px) {
  .usps h2 {
    font-size: 26px;
  }
}
@media (min-width: 768px) {
  .usps .f-1 {
    font-size: 40px;
  }
  .usps .f-2 {
    font-size: 55px;
  }
  .usps h1 {
    background: -webkit-linear-gradient(#15c22f, #0b871c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .usps h2 {
    font-size: 38px;
  }
  .usps h3 {
    font-size: 26px;
  }
  .usps p.extra {
    font-size: 34px;
  }
  .usps p.h4,
  .usps div.h4 {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .usps .f-1 {
    font-size: 55px;
  }
  .usps .f-2 {
    font-size: 68px;
  }
  .usps h2 {
    font-size: 42px;
  }
  .usps h2.lg-small {
    font-size: 38px;
  }
  .usps h3 {
    font-size: 28px;
  }
}
@media (min-width: 768px) {
  .usps {
    padding-top: 30px;
  }
}
@media (min-width: 992px) {
  .usps {
    padding-top: 40px;
  }
}
@media (min-width: 768px) {
  .usps {
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .usps {
    padding-bottom: 80px;
  }
}
.usps div.h4 {
  margin-bottom: 5px;
}
.usps .usp {
  width: 80%;
  margin: 0 auto 30px auto;
}
.usps .usp img {
  width: 90px;
  display: block;
  margin: 0 auto 10px auto;
}
.usps p {
  margin-bottom: 0;
}
.usps .usp:last-child {
  margin-bottom: 0;
}
.usps #ul-employer-offer {
  padding-left: 36px;
}
.usps #ul-employer-offer li {
  list-style: none;
  background: url("/images/check_light.png") no-repeat left 5px;
  margin: 0px 0px 20px -37px;
  padding-left: 40px;
  font-weight: 500;
}

@media (min-width: 576px) {
  .usps .usp {
    width: 100%;
  }
  .usps .usp img {
    width: 120px;
    margin: 0 auto 30px auto;
  }
}
@media (min-width: 768px) {
  .usps .usp {
    margin-bottom: 0;
  }
  .usps .usp img {
    width: 130px;
    margin: 0 auto 30px 20%;
  }
  .usps .usp p {
    margin-right: 5%;
  }
  .usps .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
  }
}
@media (min-width: 768px) {
  .usps .usp img {
    width: 140px;
  }
}
.partner {
  padding-top: 20px;
  padding-bottom: 40px;
  background-color: #DEF8F4;
  position: relative;
}
.partner h1 {
  z-index: 1;
  background: -webkit-linear-gradient(#18d534, #064a0f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.partner .f-1 {
  font-size: 26px;
  line-height: 1.1;
  font-weight: 500;
  margin-bottom: -9px;
}
.partner .f-2 {
  font-size: 34px;
  font-weight: 800;
  line-height: 1.5;
}
.partner .f-1.f-1-upper {
  margin-bottom: -9px;
}
.partner .f-2.f-2-upper {
  margin-bottom: -20px;
}
.partner .f-1.f-1-lower {
  margin-top: -4px;
}
.partner .f-1.f-2-lower {
  margin-bottom: -20px;
}
.partner h2 {
  font-size: 24px;
  line-height: 1.2;
}
.partner p.h4,
.partner div.h4 {
  font-size: 18px;
}
.partner div.h4 {
  font-weight: 800;
}
.partner p .color-app {
  color: #bbfffc;
}
.partner h2, .partner h3, .partner strong {
  font-weight: 700;
  color: #074b5e;
}
.partner h2.green, .partner h3.green {
  color: #00b372;
}
.partner h3 {
  font-size: 22px;
}
.partner div.h4 {
  color: #fff;
}
.partner p.extra {
  font-size: 28px;
  font-weight: 900;
  font-style: italic;
  line-height: 1.2;
}
.partner span.dark {
  color: #074b5e;
}
.partner span.bold {
  font-weight: 700;
}
.partner p.white {
  color: #fff;
}
@media only screen and (min-width: 430px) {
  .partner .f-1 {
    font-size: 28px;
  }
  .partner .f-2 {
    font-size: 38px;
  }
}
@media only screen and (min-width: 500px) {
  .partner .f-1 {
    font-size: 38px;
  }
  .partner .f-2 {
    font-size: 48px;
  }
  .partner p.extra {
    font-size: 32px;
  }
}
@media (min-width: 576px) {
  .partner h2 {
    font-size: 26px;
  }
}
@media (min-width: 768px) {
  .partner .f-1 {
    font-size: 40px;
  }
  .partner .f-2 {
    font-size: 55px;
  }
  .partner h1 {
    background: -webkit-linear-gradient(#15c22f, #0b871c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .partner h2 {
    font-size: 38px;
  }
  .partner h3 {
    font-size: 26px;
  }
  .partner p.extra {
    font-size: 34px;
  }
  .partner p.h4,
  .partner div.h4 {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .partner .f-1 {
    font-size: 55px;
  }
  .partner .f-2 {
    font-size: 68px;
  }
  .partner h2 {
    font-size: 42px;
  }
  .partner h2.lg-small {
    font-size: 38px;
  }
  .partner h3 {
    font-size: 28px;
  }
}
@media (min-width: 768px) {
  .partner {
    padding-top: 30px;
  }
}
@media (min-width: 992px) {
  .partner {
    padding-top: 40px;
  }
}
@media (min-width: 768px) {
  .partner {
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .partner {
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .partner h2 {
    font-size: 55px !important;
  }
}
.partner input, .partner select, .partner textarea {
  font-size: 1.1rem;
  font-style: normal;
  padding: 0 0.75rem;
  border-radius: 2rem;
}
.partner select {
  -webkit-appearance: none;
  appearance: none;
}
.partner textarea {
  padding-top: 15px;
}
.partner .cont-insurer-select::after {
  content: "▼";
  position: absolute;
  top: 5px;
  right: 3rem;
  font-size: 1.3rem;
  color: black;
}

.program-finder {
  padding-top: 20px;
  position: relative;
  padding-bottom: 60px;
  background-color: #0ca722;
}
@media (min-width: 768px) {
  .program-finder {
    padding-top: 30px;
  }
}
@media (min-width: 992px) {
  .program-finder {
    padding-top: 40px;
  }
}
@media (min-width: 992px) {
  .program-finder h2 {
    font-size: 55px !important;
  }
}
.program-finder .cont-select-program::after {
  content: "▼";
  position: absolute;
  top: 10px;
  right: 3rem;
  font-size: 1.2rem;
  color: black;
}
.program-finder #selectDisease, .program-finder #selectInsurance {
  -webkit-appearance: none;
  appearance: none;
  height: 50px;
  font-style: normal;
  border: none;
  border-radius: 2rem;
  padding: 0.375rem 3rem;
}
.program-finder #cont-btn-program {
  z-index: 999;
}

.testimonial {
  padding-bottom: 20px;
}
@media (min-width: 768px) {
  .testimonial {
    padding-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .testimonial {
    padding-bottom: 40px;
  }
}
.testimonial .items {
  margin: 0 2%;
}
.testimonial .item .item-box {
  min-height: 300px;
  border-radius: 5px;
}
.testimonial .slide-text,
.testimonial .slide-subtext {
  display: none;
}
.testimonial .slide-subtext {
  font-size: 14px;
  line-height: 1.1;
}
.testimonial .item.item2 .item-box {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  padding: 50px 30px;
}
.testimonial .item.item2 p {
  margin-top: -160px;
  position: relative;
  z-index: 1;
}
.testimonial .item.item2 .item-subtext {
  display: block;
  margin: 0 auto;
}
.testimonial .item-subtext-trangle {
  margin: -9px 0 0 10%;
  border-color: transparent rgb(35, 131, 14) transparent transparent;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 50px 50px 0;
  display: inline-block;
}
.testimonial .item-ears {
  position: relative;
  top: -70px;
  width: 210px;
  display: flex;
  margin: 0 0 0 auto;
  z-index: 0;
}
.testimonial .dark-green .item-ear {
  background: rgb(68, 197, 1);
  background: linear-gradient(194deg, rgb(68, 197, 1) 37%, rgb(68, 197, 1) 76%);
  border-top: solid 6px #44c501;
}
.testimonial .item-ear {
  display: block;
  width: 130px;
  height: 160px;
  clip-path: polygon(38% 0, 100% 0, 54% 100%, 0% 100%);
}
.testimonial .item-ear img {
  margin: 0 0 0 12px;
}
.testimonial .item-ear:last-child {
  margin-left: -35px;
}

@media (min-width: 576px) {
  .testimonial .item.item2 .item-box {
    padding: 55px 50px 50px 70px;
  }
}
@media (min-width: 768px) {
  .testimonial .slide-subtext {
    font-size: 18px;
  }
}
@media (min-width: 992px) {
  .testimonial .items {
    display: grid;
    grid-template-columns: 1fr 750px 1fr;
    grid-column-gap: 15%;
    grid-template-rows: 1fr;
    grid-row-gap: 0;
    margin: 0;
  }
  .testimonial .item1 {
    grid-area: 1/1/2/2;
  }
  .testimonial .item2 {
    grid-area: 1/2/2/3;
  }
  .testimonial .item3 {
    grid-area: 1/3/2/4;
  }
}
.text {
  margin-top: 20px;
  margin-bottom: 20px;
}
.text h1 {
  z-index: 1;
  background: -webkit-linear-gradient(#18d534, #064a0f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text .f-1 {
  font-size: 26px;
  line-height: 1.1;
  font-weight: 500;
  margin-bottom: -9px;
}
.text .f-2 {
  font-size: 34px;
  font-weight: 800;
  line-height: 1.5;
}
.text .f-1.f-1-upper {
  margin-bottom: -9px;
}
.text .f-2.f-2-upper {
  margin-bottom: -20px;
}
.text .f-1.f-1-lower {
  margin-top: -4px;
}
.text .f-1.f-2-lower {
  margin-bottom: -20px;
}
.text h2 {
  font-size: 24px;
  line-height: 1.2;
}
.text p.h4,
.text div.h4 {
  font-size: 18px;
}
.text div.h4 {
  font-weight: 800;
}
.text p .color-app {
  color: #bbfffc;
}
.text h2, .text h3, .text strong {
  font-weight: 700;
  color: #074b5e;
}
.text h2.green, .text h3.green {
  color: #00b372;
}
.text h3 {
  font-size: 22px;
}
.text div.h4 {
  color: #fff;
}
.text p.extra {
  font-size: 28px;
  font-weight: 900;
  font-style: italic;
  line-height: 1.2;
}
.text span.dark {
  color: #074b5e;
}
.text span.bold {
  font-weight: 700;
}
.text p.white {
  color: #fff;
}
@media only screen and (min-width: 430px) {
  .text .f-1 {
    font-size: 28px;
  }
  .text .f-2 {
    font-size: 38px;
  }
}
@media only screen and (min-width: 500px) {
  .text .f-1 {
    font-size: 38px;
  }
  .text .f-2 {
    font-size: 48px;
  }
  .text p.extra {
    font-size: 32px;
  }
}
@media (min-width: 576px) {
  .text h2 {
    font-size: 26px;
  }
}
@media (min-width: 768px) {
  .text .f-1 {
    font-size: 40px;
  }
  .text .f-2 {
    font-size: 55px;
  }
  .text h1 {
    background: -webkit-linear-gradient(#15c22f, #0b871c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .text h2 {
    font-size: 38px;
  }
  .text h3 {
    font-size: 26px;
  }
  .text p.extra {
    font-size: 34px;
  }
  .text p.h4,
  .text div.h4 {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .text .f-1 {
    font-size: 55px;
  }
  .text .f-2 {
    font-size: 68px;
  }
  .text h2 {
    font-size: 42px;
  }
  .text h2.lg-small {
    font-size: 38px;
  }
  .text h3 {
    font-size: 28px;
  }
}
@media (min-width: 768px) {
  .text {
    margin-top: 30px;
  }
}
@media (min-width: 992px) {
  .text {
    margin-top: 40px;
  }
}
@media (min-width: 768px) {
  .text {
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .text {
    margin-bottom: 40px;
  }
}

.footer {
  background: #031f27;
  background: linear-gradient(194deg, #074f63 37%, #031f27 76%);
}
.footer .footer-address,
.footer .footer-legal,
.footer .footer-contact {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .footer .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
  }
  .footer .footer-address {
    grid-area: 1/1/2/2;
  }
  .footer .footer-legal {
    grid-area: 1/2/2/3;
  }
  .footer .footer-contact {
    grid-area: 1/3/2/4;
  }
  .footer .footer-links {
    grid-area: 2/1/3/4;
  }
  .footer .flex-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}