.testimonial {
    @include padding-bottom-half;

    .items {
        margin: 0 2%;
    }

    .item {
        .item-box {
            min-height: 300px;
            border-radius: 5px;
        }
    }

    .slide-text,
    .slide-subtext{
        display: none;
    }

    .slide-subtext {
        font-size: 14px;
        line-height: 1.1;
    }

    .item.item2 {
        .item-box {
            display: block;
            margin: 0 auto;
            width: 100%;
            max-width: 800px;
            padding: 50px 30px;
        }

        p {
            margin-top: -160px;
            position: relative;
            z-index: 1;
        }

        .item-subtext {
            display: block;
            margin: 0 auto;
        }
    }

    .item-subtext-trangle {
        margin: -9px 0 0 10%;
        border-color: transparent rgb(35 131 14) transparent transparent;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 50px 50px 0;
        display: inline-block;
    }

    .item-ears {
        position: relative;
        top: -70px;
        width: 210px;
        display: flex;
        margin: 0 0 0 auto;
        z-index: 0;
    }

    .dark-green .item-ear {
        background: rgb(68,197,1);
        background: linear-gradient(194deg, rgba(68,197,1,1) 37%, rgba(68,197,1,1) 76%);
        border-top: solid 6px #44c501;
    }

    .item-ear {
        display: block;
        width: 130px;
        height: 160px;
        clip-path: polygon(38% 0, 100% 0, 54% 100%, 0% 100%);

        img {
            margin: 0 0 0 12px;
        }
    }

    .item-ear:last-child {
        margin-left: -35px;
    }
}

@include media-breakpoint-up(sm) {
    .testimonial {
        .item.item2 {
            .item-box {
                padding: 55px 50px 50px 70px;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .testimonial {
        .slide-subtext {
            font-size: 18px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .testimonial {
        .items {
            display: grid;
            grid-template-columns: 1fr 750px 1fr;
            grid-column-gap: 15%;
            grid-template-rows: 1fr;
            grid-row-gap: 0;
            margin: 0;
        }

        .item1 { grid-area: 1 / 1 / 2 / 2; }
        .item2 { grid-area: 1 / 2 / 2 / 3; }
        .item3 { grid-area: 1 / 3 / 2 / 4; }
    }
}
