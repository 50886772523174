.services {
    @include font-style;
    @include padding-top;
    @include padding-bottom;
    @include link-style-on-dark(#fff, $brand-heading-dark);
    position: relative;

    h2 {
        @include margin-bottom-half;
    }

    .box {
        img {
            width: 100%;
            max-height: 55px;
        }

        .box-images {
            display: flex;
            justify-content: space-between;
            max-width: 350px;
            width: 80%;
            margin: 0 auto;

            img {
                margin: 0;
                width: 40%;
            }
        }
    }

    @include media-breakpoint-up(md) {

        .box {
            .box-images {
                width: 70%;
            }
        }
    }

    @include media-breakpoint-up(lg) {

        .box {
            .box-images {
                width: 80%;
            }
        }
    }



}
