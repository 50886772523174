.usps {
    @include font-style;
    @include padding-top-half;
    @include padding-bottom;
    position: relative;

    background-color: $brand-custom;

    div.h4 {
        margin-bottom: 5px;
    }

    .usp {
        width: 80%;
        margin: 0 auto 30px auto;

        img {
            width: 90px;
            display: block;
            margin: 0 auto 10px auto;
        }
    }

    p {
        margin-bottom: 0;
    }

    .usp:last-child {
        margin-bottom: 0;
    }

    #ul-employer-offer {
        padding-left: 36px;
        
        li {
            list-style: none;
            background: url("/images/check_light.png") no-repeat left 5px;
            margin: 0px 0px 20px -37px;
            padding-left: 40px;
            font-weight: 500;
        }
    }

}


@include media-breakpoint-up(sm) {
    .usps {
        .usp {
            width: 100%;

            img {
                width: 120px;
                margin: 0 auto 30px auto;
            }
        }
    }
}


@include media-breakpoint-up(md) {
    .usps {
        .usp {
            margin-bottom: 0;

            img {
                width: 130px;
                margin: 0 auto 30px 20%;
            }

            p {
                margin-right: 5%;
            }
        }

        .grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 20px;
        }
    }
}

@include media-breakpoint-up(md) {
    .usps {
        .usp {
            img {
                width: 140px;
            }
        }
    }
}