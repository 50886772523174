.about {
    @include font-style;
    @include padding-top-half;
    @include padding-bottom;

    background-color: $brand-app;
    position: relative;

    @include media-breakpoint-up(lg) {
        h2 {
            font-size: 55px !important;
        }
    }

    .text {
        ul {
            padding-left: 36px;
        }
    }

    .text, #program-content {
        li {
            list-style: none;
            background: url("/images/check_dark.png") no-repeat left 5px;
            margin: 0px 0px 20px -37px;
            padding-left: 36px;
            font-weight: 500;
        }
    }
}

.about-veovita {
    padding-top: 100px;
}

@include media-breakpoint-up(lg) {
    .about-veovita {
        padding-top: 65px;
    }
}
