@mixin link-style-on-light ($color, $focus-bg) {

    a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):not(.style-off) {
        color: $color;
        text-decoration: none;
        border-bottom: 1px solid $color;

        &:focus {
            outline: 0 none;
            color: darken($color, 40%);
            border-bottom: 2px solid darken($color, 10%);
            background-color: lighten($focus-bg, 30%);
        }   
    }
}

@mixin link-style-on-dark ($color, $focus-bg) {

    a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link) {
        color: $color;
        text-decoration: none;
        border-bottom: 1px solid $color;

        &:focus {
            outline: 0 none;
            background-color: $focus-bg;
        }        
    }
}


.navbar {
    @include link-style-on-light($brand-link, $brand-link)
}

.footer {
    @include link-style-on-dark(#fff, #333)
}