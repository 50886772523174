.footer {
  @include gradient (#031f27, #074f63);

  .footer-address,
  .footer-legal,
  .footer-contact {
    margin-bottom: 30px;
  }

}

@include media-breakpoint-up(md) {
  .footer {
    .grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0;
      grid-row-gap: 0;
    }

    .footer-address { grid-area: 1 / 1 / 2 / 2; }
    .footer-legal { grid-area: 1 / 2 / 2 / 3; }
    .footer-contact { grid-area: 1 / 3 / 2 / 4; }
    .footer-links { grid-area: 2 / 1 / 3 / 4; }

    .flex-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
