.circles {
    @include font-style;
    @include padding-bottom-half;
    height: 400px;
}

@include media-breakpoint-up(lg) {
    .circles {
        height: auto;

        .illustration {
            width: 32%;

            img {
                width: 100%;
            }
        }
    }
}
