.boxes {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.box {
    width: 100%;
    padding: 20px 0 35px 0;
    border-radius: 5px;

    .box-inner {
        max-width: 350px;
        width: 80%;
        margin: 0 auto;
    }

    img {
        display: block;
        margin: 0 auto 5px auto;
    }
}

.contact .box, .about .box {
    ul {
        padding-left: 36px;
    }
    
    li {
        list-style: none;
        background: url("/images/check_light.png") no-repeat left 5px;
        margin: 0px 0px 20px -37px;
        padding-left: 40px;
        font-weight: 500;
    }
}

.hero,
.about,
.partner {
    background: rgb(229, 252, 248);
    background: linear-gradient(180deg, rgba(229, 252, 248, 1) 0%, rgba(196, 236, 230, 1) 56%);
}

.box.purple {
    background: rgb(148, 25, 129);
    background: linear-gradient(180deg, rgba(148, 25, 129, 1) 0%, rgba(111, 2, 94, 1) 64%);
}

.box.neutral {
    background: rgb(173 194 207);
    background: linear-gradient(180deg, rgb(173, 194, 207) 0%, rgb(138 162 174) 64%);
}

.box.green {
    background: rgb(156, 188, 19);
    background: linear-gradient(180deg, rgba(156, 188, 19, 1) 0%, rgba(120, 148, 0, 1) 64%);
}

.usps.dark-green {
    background: rgb(21, 151, 32);
    background: linear-gradient(180deg, rgba(21, 151, 32, 1) 0%, rgba(0, 106, 19, 1) 56%);
}

.item-box.grey {
    background: #a7c6d0;
}

.dark-green {
    background: rgb(68, 197, 1);
    background: linear-gradient(194deg, rgba(68, 197, 1, 1) 37%, rgba(0, 131, 13, 1) 76%);
}

.blue {
    background: rgb(9, 121, 152);
    background: linear-gradient(180deg, rgba(9, 121, 152, 1) 0%, rgba(8, 84, 106, 1) 64%);
}

.contact {
    .box.blue {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.box.orange {
    background: rgb(236, 125, 40);
    background: linear-gradient(180deg, rgba(236, 125, 40, 1) 0%, rgba(186, 93, 22, 1) 64%);
}

.box.blue-dark {
    background: rgb(5, 94, 109);
    background: linear-gradient(180deg, rgba(5, 94, 109, 1) 0%, rgba(0, 65, 76, 1) 64%);
}

@include media-breakpoint-up(md) {
    .boxes {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 50px;
        grid-row-gap: 50px;

        .box {
            padding: 30px 0 45px 0;

            .box-inner {
                width: 70%;
            }
        }
    }

    .cta .box {
        width: 100%;
        padding: 50px;
        border-radius: 5px;
    }
}