@mixin font-style {

    h1 {
        z-index: 1;
        background: -webkit-linear-gradient(#18d534, #064a0f);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .f-1 {
        font-size: 26px;
        line-height: 1.1;
        font-weight: 500;
        margin-bottom: -9px;
    }

    .f-2 {
        font-size: 34px;
        font-weight: 800;
        line-height: 1.5;
    }

    .f-1.f-1-upper {
        margin-bottom: -9px;
    }

    .f-2.f-2-upper {
        margin-bottom: -20px;
    }

    .f-1.f-1-lower {
        margin-top: -4px;
    }

    .f-1.f-2-lower {
        margin-bottom: -20px;
    }

    h2 {
        font-size: 24px;
        line-height: 1.2;
    }

    p.h4,
    div.h4 {
        font-size: 18px;
    }

    div.h4 {
        font-weight: 800;
    }

    p .color-app {
        color: #bbfffc;
    }

    h2, h3, strong {
        font-weight: 700;
        color: $brand-heading-dark;
    }

    h2, h3 {
        &.green {
            color: #00b372;
        }
    }

    h3 {
        font-size: 22px;
    }

    div.h4 {
        color: #fff;
    }

    p.extra {
        font-size: 28px;
        font-weight: 900;
        font-style: italic;
        line-height: 1.2;
    }

    span.dark {
        color: $brand-heading-dark;
    }

    span.bold {
        font-weight: 700;
    }

    p.white {
        color: #fff;
    }

    @media only screen and (min-width: 430px) {
        .f-1 {
            font-size: 28px;
        }

        .f-2 {
            font-size: 38px;
        }
    }

    @media only screen and (min-width: 500px) {
        .f-1 {
            font-size: 38px;
        }

        .f-2 {
            font-size: 48px;
        }
        p.extra {
            font-size: 32px;
        }
    }


    @include media-breakpoint-up(sm) {
        h2 {
            font-size: 26px;
        }
    }

    @include media-breakpoint-up(md) {

        .f-1 {
            font-size: 40px;
        }

        .f-2 {
            font-size: 55px;
        }

        h1 {
            background: -webkit-linear-gradient(#15c22f, #0b871c);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        h2 {
            font-size: 38px;
        }

        h3 {
            font-size: 26px;
        }

        p.extra {
            font-size: 34px;
        }

        p.h4,
        div.h4{
            font-size: 24px;
        }
    }

    @include media-breakpoint-up(lg) {


        .f-1 {
            font-size: 55px;
        }

        .f-2 {
            font-size: 68px;
        }

        h2 {
            font-size: 42px;
        }

        h2.lg-small {
            font-size: 38px;
        }

        h3 {
            font-size: 28px;
        }

    }
}

$distance-sm: 40px;
$distance-md: 60px;
$distance-lg: 80px;

// used for the distance between features
@mixin padding-bottom {
    padding-bottom: $distance-sm;

    @include media-breakpoint-up(md) {
        padding-bottom: $distance-md;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: $distance-lg;
    }
}

@mixin padding-bottom-half {
    padding-bottom: calc($distance-sm / 2);

    @include media-breakpoint-up(md) {
        padding-bottom: calc($distance-md / 2);
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: calc($distance-lg / 2);
    }
}

@mixin padding-bottom-third {
    padding-bottom: calc($distance-sm / 3);

    @include media-breakpoint-up(md) {
        padding-bottom: calc($distance-md / 3);
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: calc($distance-lg / 3);
    }
}

@mixin padding-top {
    padding-top: $distance-sm;

    @include media-breakpoint-up(md) {
        padding-top: $distance-md;
    }

    @include media-breakpoint-up(lg) {
        padding-top: $distance-lg;
    }
}

@mixin padding-top-half {
    padding-top: calc($distance-sm / 2);

    @include media-breakpoint-up(md) {
        padding-top: calc($distance-md / 2);
    }

    @include media-breakpoint-up(lg) {
        padding-top: calc($distance-lg / 2);
    }
}

@mixin padding-top-third {
    padding-top: calc($distance-sm / 3);

    @include media-breakpoint-up(md) {
        padding-top: calc($distance-md / 3);
    }

    @include media-breakpoint-up(lg) {
        padding-top: calc($distance-lg / 3);
    }
}

// used for the distance between features
@mixin margin-top {
    margin-top: $distance-sm;

    @include media-breakpoint-up(md) {
        margin-top: $distance-md;
    }

    @include media-breakpoint-up(lg) {
        margin-top: $distance-lg;
    }
}

// used for the distance between features
@mixin margin-bottom {
    margin-bottom: $distance-sm;

    @include media-breakpoint-up(md) {
        margin-bottom: $distance-md;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: $distance-lg;
    }
}

// used for the distance within a feature
@mixin margin-top-half {
    margin-top: calc($distance-sm / 2);

    @include media-breakpoint-up(md) {
        margin-top: calc($distance-md / 2);
    }

    @include media-breakpoint-up(lg) {
        margin-top: calc($distance-lg / 2);
    }
}

// used for the distance within a feature
@mixin margin-bottom-half {
    margin-bottom: calc($distance-sm / 2);

    @include media-breakpoint-up(md) {
        margin-bottom: calc($distance-md / 2);
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: calc($distance-lg / 2);
    }
}

@mixin hyphens {
    -moz-hyphens: auto;
    -o-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.hyphens {
    @include hyphens;
}

@mixin gradient($dark, $light) {
    background: $dark;
    background: linear-gradient(194deg, $light 37%, $dark 76%);
}


@mixin link-style-on-light($color, $focus-bg) {

    a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.style-off) {
        color: darken($color, 15%);
        text-decoration: none;
        border-bottom: 1px solid darken($color, 15%);

        &:focus {
            outline: 0 none;
            color: darken($color, 40%);
            border-bottom: 2px solid darken($color, 10%);
            background-color: lighten($focus-bg, 40%);
        }
    }
}

@mixin link-style-on-dark($color, $focus-bg) {

    a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.style-off) {
        color: $color;
        text-decoration: none;
        border-bottom: 1px solid $color;

        &:focus {
            outline: 0 none;
            background-color: $focus-bg;
        }
    }
}
