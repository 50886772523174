.cta {
    #col-cta-contact {
        font-weight: 500;
    }

    #cta-heading {
        font-size: 2rem;
        line-height: 1.2;
    }

    #cont-contact-emergency {
        font-size: 17px;

        a {
            color: #fff;
            text-decoration: none;
        }
    }

    .emergency-green-gradient {
        background: linear-gradient(161deg, #64be37 0%, #398b3d 100%);
    }

    .emergency-blue-gradient {
        background: linear-gradient(194deg, #074f63 37%, #031f27 76%);
    }
}
