.partner {
    @include font-style;
    @include padding-top-half;
    @include padding-bottom;

    background-color: $brand-app;
    position: relative;

    @include media-breakpoint-up(lg) {
        h2 {
            font-size: 55px !important;
        }
    }

    input, select, textarea {
        font-size: 1.1rem;
        font-style: normal;
        padding: 0 0.75rem;
        border-radius: 2rem;
    }

    select {
        -webkit-appearance: none;
        appearance: none;
    }

    textarea {
        padding-top: 15px;
    }

    .cont-insurer-select::after {
        content: "▼";
        position: absolute;
        top: 5px;
        right: 3rem;
        font-size: 1.3rem;
        color: black;
    }
}
