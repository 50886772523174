$width-sm: 135px;
$width-md: 235px;
$width-xl: 335px;

// this cuts the horizontal overflow off
// so each section can have overflow vertically
.body-content {
    overflow: hidden;
}

.theNumber {
    position: absolute;
    top: -26px;
    left: -16px;
    max-width: $width-sm;
    display: grid;
    grid-template-columns: calc($width-sm / 12) calc($width-sm / 1.5) 1fr;
    grid-template-rows: 50px 200px 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    z-index: 2;

    .number-title {
        grid-area: 2 / 2 / 3 / 3;
    }

    .number-image {
        grid-area: 1 / 1 / 4 / 4;
    }

    .number-title {
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        color: $brand-heading-dark;
        font-size: 13px;
        white-space: pre;
        opacity: 0.5;

        span {
            padding: 0 15px;
            border-top: solid 1px;
            border-left: solid 1px;
        }
    }

    .number-image {
        width: 100%;

        img {
            width: 100%;
        }
    }
}

.theNumber {
    left: -25px;
}

.theNumber.right {
    top: -120px;
    left: auto;
    right: -15px;
}

.theNumber.number-top {
    top: 65px;
}

@include media-breakpoint-up(md) {
    .theNumber {
        top: -120px;
        max-width: $width-md;
        grid-template-columns: calc($width-md / 12) calc($width-md / 1.5) 1fr;
    }
}

@include media-breakpoint-up(xl) {
    .theNumber {
        max-width: $width-xl;
        grid-template-columns: calc($width-xl / 12) calc($width-xl / 1.5) 1fr;
        left: -20px;
    }
}
