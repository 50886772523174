.container-big {
    width: 100%;
    padding: 0 10px;
    margin: 0 auto;
    overflow: hidden;
}

@include media-breakpoint-up(sm) {
    .container-big {
        max-width: 540px;
        padding: 0;
    }
}

@include media-breakpoint-up(md) {
    .container-big {
        max-width: 780px;
    }

    .contact {
        .container-big {
            padding: 0 40px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .container-big {
        max-width: 1000px;
    }

    .contact {
        .container-big {
            padding: 0;
        }
    }
}

@include media-breakpoint-up(xl) {
    .container-big {
        max-width: 1200px;
    }
}