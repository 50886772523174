.contact {
    @include font-style;
    @include padding-top;
    @include padding-bottom;
    position: relative;

    @media only screen and (min-width: 500px) {
        h2 {
            max-width: 80%;
        }
    }

    @include media-breakpoint-up(lg) {
        h2 {
            font-size: 55px !important;
        }
    }

    .content {
        p {
            @include padding-bottom-half;
        }
    }
}

.career {
    position: relative;

    img.icon {
        height: 300px;
        padding-top: 40px;
    }

    .bg-light-blue {
        background-color: #e7fefa;
    }
    
    .custom-blue {
        color: #105465;
    }

    .custom-h3 {
        font-size: 35px;
    }

    ul {
        padding-left: 36px;
    }

    li {
        list-style: none;
        background: url("/images/check_dark.png") no-repeat left 5px;
        margin: 0px 0px 20px -37px;
        padding-left: 36px;
        font-weight: 500;
    }

    @media only screen and (min-width: 500px) {
        #contact-info {
            font-size: 1.4rem;
        }
    }

    .arrow-job-link {
        font-size: 45px;
        color: #155c67;
    }

    @include media-breakpoint-up(lg) {
        .arrow-job-link {
            font-size: 60px;
        }
    }

    #jobs {
        a {
            text-decoration: none;
        }
    }
}
